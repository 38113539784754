import React, { useState, useEffect, useRef } from "react";
import { FaPlus } from "react-icons/fa";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import QuillEditor from "../QuillEditor";
import axios from "../../helpers/axios";
import { useForm } from "react-hook-form";
import { createArticleSchema } from "../validations/articleSchema";
import { yupResolver } from "@hookform/resolvers/yup";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const CreateArticleModal = ({ articles, addArticle }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [userInfo, setUserInfo] = useState({});
  const [articleData, setArticleData] = useState({
    title: "",
    description: "",
    poster_image: null,
    communityImage: null,
  });
  const [categories, setCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [showArticleModal, setShowArticleModal] = useState(false);

  useEffect(() => {
    const savedUserInfo = localStorage.getItem("userInfo");
    if (savedUserInfo) {
      setUserInfo(JSON.parse(savedUserInfo));
    }
  }, []);

  useEffect(() => {
    axios
      .get("/add-categories/")
      .then((res) => {
        // console.log("res", res);
        setCategories(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(createArticleSchema),
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setArticleData({ ...articleData, [name]: value });
  };

  const handleQuillChange = (value) => {
    setArticleData({ ...articleData, description: value });
  };

  const handleImageChange = (e) => {
    const { name, files } = e.target;
    setArticleData({ ...articleData, [name]: files[0] });
  };

  const handleCategoryChange = (e) => {
    const { value, checked } = e.target;
    const categories = checked
      ? [...articleData.categories, value]
      : articleData.categories.filter((category) => category !== value);
    setArticleData({ ...articleData, categories });
  };

  const handleFileChange = (e) => {
    setArticleData({ ...articleData, communityImage: e.target.files[0] });
  };
  const resetForm = () => {
    setArticleData({
      title: "",
      description: "",
      poster_image: null,
      categories: [],
      communityImage: null,
    });
    reset();
  };
  const handleCreateArticle = async () => {
    setShowArticleModal(true);
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append("title", articleData.title);
      formData.append("description", articleData.description);
      // articleData.categories.forEach((category, index) => {
      //   formData.append(`categories[${index}]`, category);
      // });
      if (articleData.poster_image) {
        formData.append(
          "poster_image",
          articleData.poster_image,
          articleData.poster_image.name
        );
      }
      formData.append("user_id", userInfo.userId);
      formData.append("categories", selectedCategories);

      await axios
        .post("/add-article/", formData)
        .then((res) => {
          if (res.data) {
            toast.success("Article created successfully");
            setLoading(false);
            addArticle(res.data);
            setIsOpen(false);
            setArticleData({
              title: "",
              description: "",
              poster_image: null,
              categories: [],
              communityImage: null,
            });
            resetForm();
          } else {
            toast.error("error happen while creating article, try again later");
            setLoading(false);
          }
        })
        .catch((error) => {
          toast.error("error happen while creating article, try again later");
          setLoading(false);
        });
    } catch (error) {
      toast.error("Error while create article, try later");
      setLoading(false);
      console.error("Error creating article:", error);
      if (error.response) {
        console.error("Server responded with:", error.response.data);
      } else {
        console.error("No response from server");
      }
    }
  };
  return (
    <div className="">
      <button
        className="px-4 py-2 bg-[#198754] text-white rounded-md flex items-center"
        onClick={() => setIsOpen(true)}
      >
        <div>
          <FaPlus />
        </div>
        <div className="ml-2">Create Article</div>
      </button>

      {isOpen && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-md shadow-md w-[90%] md:w-[50%] max-h-[95vh] overflow-y-scroll">
            <div className="flex justify-between items-center">
              <h2 className="text-2xl font-semibold">Create An Article</h2>
              <button
                className="text-gray-600 hover:text-gray-900"
                onClick={() => setIsOpen(false)}
              >
                <svg
                  className="h-6 w-6"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
            <div className="mt-4">
              <form encType="multipart/form-data" className="space-y-6">
                <div>
                  <label
                    htmlFor="title"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Title
                  </label>
                  <input
                    type="text"
                    name="title"
                    id="title"
                    value={articleData.title}
                    onChange={handleInputChange}
                    className="input input-bordered w-full"
                  />
                </div>
                <div>
                  <label
                    htmlFor="description"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Description
                  </label>
                  <QuillEditor
                    value={articleData.description}
                    onChange={handleQuillChange}
                  />
                </div>
                <div>
                  <label
                    htmlFor="categories"
                    className="block text-sm font-medium text-gray-700 mb-2"
                  >
                    Categories
                  </label>
                  <div className="mt-1">
                    <select
                      id="categories"
                      name="categories"
                      className="select select-bordered w-full"
                      multiple
                      onChange={(e) =>
                        setSelectedCategories(
                          Array.from(e.target.selectedOptions, (option) =>
                            parseInt(option.value, 10)
                          )
                        )
                      }
                    >
                      {categories.map((category) => (
                        <option key={category.id} value={category.id}>
                          {category.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <div>
                  <label
                    htmlFor="poster_image"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Poster Image
                  </label>
                  <input
                    type="file"
                    name="poster_image"
                    id="poster_image"
                    onChange={handleImageChange}
                    className="file-input file-input-bordered file-input-md w-full"
                  />
                </div>
              </form>
            </div>
            <div className="mt-6 flex justify-end">
              <button
                className="px-4 py-2 bg-gray-600 text-white rounded-md"
                onClick={() => setIsOpen(false)}
              >
                Close
              </button>
              <button
                className="flex items-center ml-2 px-4 py-2 bg-blue-600 text-white rounded-md"
                onClick={handleCreateArticle}
                disabled={loading}
              >
                {loading && (
                  <span class="loading loading-spinner loading-sm mr-2"></span>
                )}{" "}
                Create Article
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CreateArticleModal;
