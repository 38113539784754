import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "../../../helpers/axios";
import { toast, ToastContainer } from "react-toastify";
import { GoBack } from "../../GoBack";

export const EditCommunity = () => {
  const { id } = useParams();
  const [community, setCommunity] = useState(null);
  const [loading, setLoading] = useState(true);
  const [updating, setUpdating] = useState(false);
  const [posterImagePreview, setPosterImagePreview] = useState("");
  const [categories, setCategories] = useState([]);

  const navigate = useNavigate();
  // redirect back to login
  useEffect(() => {
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    if (!userInfo?.userEmail) {
      navigate("/login");
      return;
    }
  }, []);

  useEffect(() => {
    const fetchCommunity = async () => {
      try {
        const response = await axios.get(`/communities/${id}/`);
        setCommunity(response.data);
        setPosterImagePreview(response.data.poster_image);
      } catch (error) {
        console.error("Error fetching community:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchCommunity();
  }, [id]);

  useEffect(() => {
    axios
      .get("/add-categories/")
      .then((res) => {
        setCategories(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setUpdating(true);
    try {
      const formData = new FormData();
      for (const key in community) {
        formData.append(key, community[key]);
      }
      const response = await axios.patch(`/communities/${id}/edit/`, formData);
      if (response.data) {
        toast.success("Community updated successfully");
      } else {
        toast.error("Failed to update community");
      }
    } catch (error) {
      console.error("Error updating community:", error);
      toast.error("Failed to update community");
    } finally {
      setUpdating(false);
    }
  };

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (name === "poster_image") {
      if (files && files.length > 0) {
        const reader = new FileReader();
        reader.onload = (e) => setPosterImagePreview(e.target.result);
        reader.readAsDataURL(files[0]);
        setCommunity((prevCommunity) => ({
          ...prevCommunity,
          [name]: files[0],
        }));
      } else {
        setPosterImagePreview(value);
        setCommunity((prevCommunity) => ({
          ...prevCommunity,
          [name]: value,
        }));
      }
    } else {
      setCommunity((prevCommunity) => ({
        ...prevCommunity,
        [name]: value,
      }));
    }
  };

  if (loading) {
    return (
      <div class="flex justify-center w-[100%]">
        <span class="loading loading-bars loading-lg"></span>
      </div>
    );
  }

  return (
    <div className="container mx-auto my-5">
      <GoBack />
      <ToastContainer />
      <h1 className="text-center text-3xl font-bold">Edit Community</h1>
      <form
        onSubmit={handleSubmit}
        className="mt-5 p-5 border rounded shadow w-full sm:w-[90%] md:w-[80%] lg:w-[70%] xl:w-[60%] mx-auto"
        encType="multipart/form-data"
      >
        <div className="mb-4">
          <label className="block mb-2">Name:</label>
          <input
            type="text"
            name="name"
            value={community.name}
            onChange={handleChange}
            className="w-full p-2 border rounded"
          />
        </div>
        <div className="mb-4">
          <label className="block mb-2">Description:</label>
          <textarea
            name="description"
            value={community.description}
            onChange={handleChange}
            className="w-full p-2 border rounded"
            rows="6"
          />
        </div>
        <div className="mb-4">
          <label className="block mb-2">Category:</label>
          <select
            name="categories"
            value={community.categories[0] || ""}
            onChange={(e) =>
              setCommunity((prevCommunity) => ({
                ...prevCommunity,
                categories: [parseInt(e.target.value)],
              }))
            }
            className="select select-bordered w-full"
          >
            <option value="">Select a category</option>
            {categories.map((category) => (
              <option key={category.id} value={category.id}>
                {category.name}
              </option>
            ))}
          </select>
          {community.categories[0] && (
            <p className="mt-2 text-sm text-gray-600">
              Selected Category:{" "}
              <span className="font-medium">
                {
                  categories.find((cat) => cat.id === community.categories[0])
                    ?.name
                }
              </span>
            </p>
          )}
        </div>
        <div className="mb-4">
          <label className="block mb-2">Poster Image URL:</label>
          <input
            type="file"
            name="poster_image"
            className="w-full p-2 border rounded"
            onChange={handleChange}
            accept="image/*"
          />
          {posterImagePreview && (
            <div className="mt-2">
              <img
                src={posterImagePreview}
                alt="Poster Preview"
                className="w-[100px] h-[100px] object-cover my-2"
              />
            </div>
          )}
        </div>
        <button
          type="submit"
          className="bg-blue-500 text-white px-4 py-2 rounded"
          disabled={updating}
        >
          {updating ? "Updating..." : "Update Community"}
        </button>
      </form>
    </div>
  );
};
