import React, { useState, useEffect, useRef, useCallback } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  Modal,
  Form,
} from "react-bootstrap";
import "react-quill/dist/quill.snow.css"; // import the styles
import axios from "../../../helpers/axios";
import userprofile from "../../../imgs/Logowhite.jpg";
import "../../styles/account.css";
import Notiflix from "notiflix";

import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import CreateArticleModal from "../../Actions/CreateArticleModal";
import DOMPurify from "dompurify";
import CreateCourseModal from "../../Actions/CreateCourseModal";
import CreateCommunityModal from "../../Actions/CreateCommunityModal";
import CreateProjectModal from "../../Actions/CreateProjectModal";
import DeleteCourseModal from "../../Actions/DeleteCourseModal";
import { MdEdit } from "react-icons/md";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Description from "../../Description";
import { DeleteArticleModal } from "../../Actions/DeleteArticleModal";
import { DeleteProjectModal } from "../../Actions/DeleteProjectModal";
import { Link, useNavigate } from "react-router-dom";
import { DeleteCommunityModal } from "../../Actions/DeleteCommunityModal";
import CreateEvent from "../../Actions/CreateEvent";
import CreateVideo from "../../Actions/CreateVideo";
import { truncateText } from "../../../utils";
import DeleteVideoModal from "../../Actions/DeleteVideoModal";

function CountCard({ title, count, bgColorClass }) {
  return (
    <Card className={`count-card ${bgColorClass}`}>
      <Card.Body>
        <h6>{title}</h6>
        <h2>{count}</h2>
      </Card.Body>
    </Card>
  );
}
function AccountHome({ auth }) {
  const [userInfo, setUserInfo] = useState({});

  const [articles, setArticles] = useState([]);
  const [articleLoading, setArticleLoading] = useState(true);
  const [courses, setCourses] = useState([]);
  const [courseLoading, setCourseLoading] = useState(true);
  const [communities, setCommunities] = useState([]);
  const [communityLoading, setCommunityLoading] = useState(true);
  const [projects, setProjects] = useState([]);
  const [projectsLoading, setProjectsLoading] = useState(true);
  const [loading, setLoading] = useState(true);
  const [userProfile, setUserProfile] = useState({});
  const [activeTab, setActiveTab] = useState("events");
  const [liveEvents, setLiveEvents] = useState([]);
  const [liveEventsLoading, setLiveEventsLoading] = useState(true);
  const [waitingEvents, setWaitingEvents] = useState([]);
  const [waitingEventsLoading, setWaitingEventsLoading] = useState(true);
  const [eventTab, setEventTab] = useState("waiting");
  const [videos, setVideos] = useState([]);
  const [videoLoading, setVideoLoading] = useState(true);
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [newCommunityId, setNewCommunityId] = useState(null); // Track newly created community ID
  const newCommunityRef = useRef(null); // Reference to the new community element
  let no = 1;
  let cNo = 1;
  let comNo = 1;
  let projNo = 1;

  const navigate = useNavigate();
  // redirect back to login
  useEffect(() => {
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    if (!userInfo?.userEmail) {
      navigate("/login");
      return;
    }
  }, []);

  // assign login data to state
  useEffect(() => {
    const savedUserInfo = localStorage.getItem("userInfo");
    if (savedUserInfo) {
      setUserInfo(JSON.parse(savedUserInfo));
    }
  }, []);

  useEffect(() => {
    if (userInfo.userId) {
      axios
        .get(`/auth/profile/${userInfo.userId}/`)
        .then((res) => {
          setLoading(false);
          if (res.data) {
            setUserProfile(res.data);
          } else {
            // setLoading(false);
          }
        })
        .catch((error) => {
          setLoading(false);
        });
    }
  }, [userInfo]);

  // get articles

  const fetchArticles = useCallback(() => {
    axios
      .get("/add-article/")
      .then((res) => {
        setArticleLoading(false);
        const filteredArticles = res?.data.filter(
          (article) => article.author.id === userInfo.userId
        );
        setArticles(filteredArticles);
      })
      .catch((error) => {
        setArticleLoading(false);
      });
  }, [userInfo.userId]);

  const fetchCourses = useCallback(() => {
    axios
      .get("/courses/")
      .then((res) => {
        setCourseLoading(false);
        const filteredCourses = res.data.filter(
          (course) => course.teacher === userInfo.userId
        );
        setCourses(filteredCourses);
      })
      .catch((error) => {
        console.error("Error:", error);
        setCourseLoading(false);
      });
  }, [userInfo.userId]);

  const fetchCommunities = useCallback(async () => {
    try {
      const response = await axios.get("/communities/");
      setCommunityLoading(false);
      if (response) {
        console.log("response", response);
        const filteredCommunities = response.data.filter(
          (community) => community.admin === userInfo.userId
        );
        setCommunities(filteredCommunities.reverse());
      }
    } catch (error) {
      setCommunityLoading(false);
      console.error("Failed to fetch communities:", error);
    }
  }, [newCommunityId, userInfo.userId]);

  // useEffect(() => {
  //   fetchCommunities();
  // }, [fetchCommunities]);

  // Scroll to the newly created community
  useEffect(() => {
    if (newCommunityId && newCommunityRef.current) {
      newCommunityRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [communities]);

  const fetchProjects = useCallback(async () => {
    try {
      const response = await axios.get("/projects/");
      if (response) {
        setProjectsLoading(false);
        const filteredProjects = response.data.filter(
          (project) => project.author.id === userInfo.userId
        );
        setProjects(filteredProjects);
      }
    } catch (error) {
      setProjectsLoading(false);
      console.error("Failed to fetch projects:", error);
    }
  }, [userInfo.userId]);

  // useEffect(() => {
  //   fetchProjects();
  // }, [userInfo.userId]);

  //  events

  const handleActiveTab = (tab) => {
    setActiveTab(tab);
  };

  const fetchLiveEvents = useCallback(async () => {
    try {
      const res = await axios.get("/nsangiza/events/live/");
      setLiveEventsLoading(false);
      const filteredWaitingEvents = res.data.filter(
        (event) => event.user === userInfo.userId
      );
      setLiveEvents(filteredWaitingEvents);
    } catch (error) {
      console.error("Error:", error);
      setLiveEventsLoading(false);
    }
  }, [userInfo.userId]);

  const fetchWaitingEvents = useCallback(async () => {
    try {
      const res = await axios.get("/nsangiza/events/waiting/");
      setWaitingEventsLoading(false);
      const filteredWaitingEvents = res.data.filter(
        (event) => event.user === userInfo.userId
      );
      setWaitingEvents(filteredWaitingEvents);
    } catch (error) {
      console.error("Error:", error);
      setWaitingEventsLoading(false);
    }
  }, [userInfo.userId]);

  const fetchVideos = useCallback(async () => {
    try {
      const res = await axios.get("/nsangiza/video/list");
      setVideoLoading(false);
      console.log("res", res.data);
      const filteredVideo = res.data.filter(
        (event) => event.author.id === userInfo.userId
      );
      console.log("filt", filteredVideo);
      setVideos(filteredVideo);
    } catch (error) {
      console.error("Error:", error);
      setVideoLoading(false);
    }
  }, [userInfo.userId]);
  useEffect(() => {
    switch (activeTabIndex) {
      case 0:
        fetchArticles();
        break;
      case 1:
        fetchCourses();
        break;
      case 2:
        fetchCommunities();
        break;
      case 3:
        fetchProjects();
        break;
      case 4:
        fetchLiveEvents();
        fetchWaitingEvents();
        fetchVideos();
        break;
      default:
        break;
    }
  }, [
    activeTabIndex,
    fetchArticles,
    fetchCourses,
    fetchCommunities,
    fetchProjects,
    fetchLiveEvents,
    fetchWaitingEvents,
    fetchVideos,
  ]);

  // update courses after create new one without refresh
  const addArticle = (newArticle) => {
    setArticles((prevArticles) => [...prevArticles, newArticle]);
  };

  // update courses after create new one without refresh
  const addCommunity = (newCommunity) => {
    setCommunities((prevCommunities) => [newCommunity, ...prevCommunities]);
  };

  // update courses after create new one without refresh
  const addCourse = (newCourse) => {
    setCourses((prevCourses) => [...prevCourses, newCourse]);
  };

  // update project after create new one without refresh
  const addProject = (newProject) => {
    setProjects((prevProject) => [...prevProject, newProject]);
  };
  // update waiting events after create new one without refresh
  const addEvent = (newEvent) => {
    setWaitingEvents((prevEvent) => [...prevEvent, newEvent]);
  };

  const addVideo = (newVideo) => {
    setVideos((prevVideo) => [...prevVideo, newVideo]);
  };

  // update courses after delete course without refresh
  const removeCourse = (courseId) => {
    setCourses((prevCourses) =>
      prevCourses.filter((course) => course.id != courseId)
    );
  };
  // update courses after delete course without refresh
  const removeArticle = (articleId) => {
    setArticles((prevArticle) =>
      prevArticle.filter((article) => article.id != articleId)
    );
  };
  const removeProject = (projectId) => {
    setProjects((prevProject) =>
      prevProject.filter((project) => project.id != projectId)
    );
  };
  const removeVideo = (videoId) => {
    setVideos((prevVideo) => prevVideo.filter((video) => video.id != videoId));
  };

  // remove community
  const removeCommunity = (communityId) => {
    setCommunities((prevComm) =>
      prevComm.filter((community) => community.id != communityId)
    );
  };

  return (
    <Container className="mt-5">
      <ToastContainer />
      <Row>
        <Col>
          <Card className="jumbotron">
            <Card.Body>
              <Row>
                <Col
                  md={3}
                  className="flex justify-center items-center py-3 md:py-2"
                >
                  {loading ? (
                    <div>
                      <span class="loading loading-spinner loading-sm mr-2"></span>
                    </div>
                  ) : (
                    <div class="avatar">
                      <div class="w-40 rounded-xl">
                        <img src={userProfile.profile_picture} />
                      </div>
                    </div>
                  )}
                </Col>
                <Col md={3}>
                  {userInfo && (
                    <>
                      <h3>{auth?.fullName || userInfo.fullName}</h3>
                      <p>{auth?.userEmail || userInfo.userEmail}</p>
                      <p>phone: {auth?.phone || userInfo.phone}</p>
                      {/* <p>status: {userInfo.status}</p> */}
                      <p>Role: {auth?.role || userInfo.role}</p>
                    </>
                  )}
                </Col>
                <Col md={6}>
                  <Row>
                    <Col md={4}>
                      <CountCard
                        title="My Courses"
                        count={courses.length}
                        bgColorClass="my-courses"
                      />
                    </Col>
                    <Col md={4}>
                      <CountCard
                        title="My Projects"
                        count={projects.length}
                        bgColorClass="my-projects"
                      />
                    </Col>
                    <Col md={4}>
                      <CountCard
                        title="My Community"
                        count={communities.length}
                        bgColorClass="my-community"
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <div className="my-5">
        <Tabs onSelect={(index) => setActiveTabIndex(index)}>
          <TabList className={{ marginBottom: "30px" }}>
            <Tab>My Articles</Tab>
            <Tab>My Course</Tab>
            <Tab>My Community</Tab>
            <Tab>My Project</Tab>
            <Tab>Nsangiza</Tab>
          </TabList>

          {/* article */}
          <TabPanel>
            <div className=" flex justify-end">
              <CreateArticleModal articles={articles} addArticle={addArticle} />
            </div>
            <div className="my-3">
              {articleLoading ? (
                <div class="flex justify-center w-[100%]">
                  <span class="loading loading-bars loading-lg"></span>
                </div>
              ) : (
                <div>
                  {articles?.length ? (
                    <div className="overflow-x-auto">
                      <table className="min-w-full bg-white border border-gray-200 rounded-lg shadow-md">
                        <thead>
                          <th className="py-3 px-6 bg-gray-100 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">
                            #
                          </th>
                          <th className="py-3 px-6 bg-gray-100 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">
                            Article Name
                          </th>
                          <th className="py-3 px-6 bg-gray-100 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">
                            Article Description
                          </th>
                          <th className="py-3 px-6 bg-gray-100 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">
                            Action
                          </th>
                        </thead>
                        <tbody>
                          {articles.map((article) => (
                            <tr class="bg-gray-50 border-b">
                              <td className="py-4 px-6 text-sm text-gray-500">
                                {no++}
                              </td>
                              <td className="flex items-center py-4 px-6 text-sm text-gray-500">
                                <div>
                                  {/* <img
                                    src={article.poster_image}
                                    alt="article image"
                                    className="w-16 h-16 rounded-full object-cover"
                                  /> */}
                                  <div class="avatar">
                                    <div class="w-20 rounded-xl">
                                      <img src={article.poster_image} />
                                    </div>
                                  </div>
                                </div>
                                <div className="ml-3">
                                  <b>{article.title}</b>
                                </div>
                              </td>
                              <td className="py-4 px-6 text-sm text-gray-500">
                                <Description
                                  description={article.description}
                                  showMore={false}
                                  maxLength={150}
                                />
                              </td>
                              <td className="flex items-center py-4 px-6 text-sm text-gray-500">
                                <div className="">
                                  <Link
                                    to={`/account/article/${article.id}`}
                                    class=""
                                  >
                                    <MdEdit size={24} />
                                  </Link>
                                </div>
                                <div>
                                  <DeleteArticleModal
                                    id={article.id}
                                    removeArticle={removeArticle}
                                  />
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  ) : (
                    <div>No article created</div>
                  )}
                </div>
              )}
            </div>
          </TabPanel>
          {/* course */}
          <TabPanel>
            <div className=" flex justify-between items-center">
              <div className="font-semibold text-lg">Mooc Courses</div>
              <CreateCourseModal auth={auth} addCourse={addCourse} />
            </div>
            <div className="my-3">
              {courseLoading ? (
                <div class="flex justify-center w-[100%]">
                  <span class="loading loading-bars loading-lg"></span>
                </div>
              ) : (
                <div>
                  {courses?.length ? (
                    <div className="overflow-x-auto">
                      <table className="min-w-full bg-white border border-gray-200 rounded-lg shadow-md">
                        <thead>
                          <th className="py-3 px-6 bg-gray-100 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">
                            #
                          </th>
                          <th className="py-3 px-6 bg-gray-100 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">
                            Course Name
                          </th>
                          <th className="py-3 px-6 bg-gray-100 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">
                            Course Description
                          </th>
                          <th className="py-3 px-6 bg-gray-100 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">
                            Action
                          </th>
                        </thead>
                        <tbody>
                          {courses.map((course) => (
                            <tr class="bg-gray-50 border-b">
                              <td className="py-4 px-6 text-sm text-gray-500">
                                {cNo++}
                              </td>
                              <td className="flex items-center py-4 px-6 text-sm text-gray-500">
                                <div>
                                  <img
                                    src={course.course_image}
                                    alt="course image"
                                    className="w-12 h-12 rounded-full"
                                  />
                                </div>
                                <div className="ml-3">
                                  <b>{course.title}</b>
                                </div>
                              </td>
                              <td className="py-4 px-6 text-sm text-gray-500">
                                <Description
                                  description={course.description}
                                  showMore={false}
                                  maxLength={150}
                                />
                              </td>
                              <td className="flex items-center py-4 px-6 text-sm text-gray-500">
                                <div className="">
                                  <Link
                                    to={`/account/course/${course.id}`}
                                    class=""
                                  >
                                    <MdEdit size={24} />
                                  </Link>
                                </div>
                                <div>
                                  <DeleteCourseModal
                                    id={course.id}
                                    removeCourse={removeCourse}
                                  />
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  ) : (
                    <div>No course created</div>
                  )}
                </div>
              )}
            </div>
          </TabPanel>
          {/* community */}
          <TabPanel>
            <div className=" flex justify-end">
              <CreateCommunityModal addCommunity={addCommunity} auth={auth} />
            </div>
            <div className=" mt-4">
              {communityLoading ? (
                <div class="flex justify-center w-[100%]">
                  <span class="loading loading-bars loading-lg"></span>
                </div>
              ) : (
                <div>
                  {communities?.length ? (
                    <div className="overflow-x-auto">
                      <table className="min-w-full bg-white border border-gray-200 rounded-lg shadow-md">
                        <thead>
                          <th className="py-3 px-6 bg-gray-100 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">
                            #
                          </th>
                          <th className="py-3 px-6 bg-gray-100 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">
                            Community Name
                          </th>
                          <th className="py-3 px-6 bg-gray-100 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">
                            Community Description
                          </th>
                          <th className="py-3 px-6 bg-gray-100 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">
                            Created Date
                          </th>
                          <th className="py-3 px-6 bg-gray-100 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">
                            Action
                          </th>
                        </thead>
                        <tbody>
                          {communities.map((community, index) => (
                            <tr class="bg-gray-50 border-b" key={index}>
                              <td className="py-4 px-6 text-sm text-gray-500">
                                {comNo++}
                              </td>
                              <td className="flex items-center py-4 px-6 text-sm text-gray-500">
                                <div className="avatar">
                                  <div className="w-16  rounded-full">
                                    <img
                                      src={community.poster_image}
                                      alt={community.name}
                                      className="border"
                                    />
                                  </div>
                                </div>
                                <div className="ml-3">
                                  <b>{community.name}</b>
                                </div>
                              </td>
                              <td className="py-4 px-6 text-sm text-gray-500">
                                <Description
                                  description={truncateText(
                                    community.description
                                  )}
                                  showMore={false}
                                  maxLength={150}
                                />
                              </td>
                              <td>
                                <div className="ml-3">
                                  {new Date(
                                    community.created_date
                                  ).toLocaleDateString()}
                                </div>
                              </td>
                              <td className="flex items-center py-4 px-6 text-sm text-gray-500">
                                <div className="">
                                  <Link
                                    to={`/account/community/${community.id}`}
                                    class=""
                                  >
                                    <MdEdit size={24} />
                                  </Link>
                                </div>
                                <div>
                                  <DeleteCommunityModal
                                    id={community.id}
                                    removeCommunity={removeCommunity}
                                  />
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  ) : (
                    <div>No community created</div>
                  )}
                </div>
              )}
            </div>
          </TabPanel>
          {/* projects */}
          <TabPanel>
            <div className=" flex justify-end">
              <CreateProjectModal addProject={addProject} />
            </div>
            <div>
              {projectsLoading ? (
                <div class="flex justify-center w-[100%]">
                  <span class="loading loading-bars loading-lg"></span>
                </div>
              ) : projects.length ? (
                <div className="overflow-x-auto relative shadow-md sm:rounded-lg mt-4">
                  <table className="w-full text-sm text-left text-gray-500">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                      <tr>
                        <th
                          scope="col"
                          className="py-3 px-6 bg-gray-100 text-left text-xs font-medium text-gray-600 uppercase tracking-wider"
                        >
                          #
                        </th>
                        <th
                          scope="col"
                          className="py-3 px-6 bg-gray-100 text-left text-xs font-medium text-gray-600 uppercase tracking-wider"
                        >
                          Title
                        </th>
                        <th
                          scope="col"
                          className="py-3 px-6 bg-gray-100 text-left text-xs font-medium text-gray-600 uppercase tracking-wider"
                        >
                          Authors
                        </th>
                        <th
                          scope="col"
                          className="py-3 px-6 bg-gray-100 text-left text-xs font-medium text-gray-600 uppercase tracking-wider"
                        >
                          Level
                        </th>
                        <th
                          scope="col"
                          className="py-3 px-6 bg-gray-100 text-left text-xs font-medium text-gray-600 uppercase tracking-wider"
                        >
                          Submission Date
                        </th>
                        <th
                          scope="col"
                          className="py-3 px-6 bg-gray-100 text-left text-xs font-medium text-gray-600 uppercase tracking-wider"
                        >
                          Actions
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {projects.map((project) => (
                        <tr
                          key={project.id}
                          className="bg-white border-b hover:bg-gray-50"
                        >
                          <td className="py-4 px-6">{projNo++}</td>
                          <td className="py-4 px-6">{project.topics}</td>
                          <td className="py-4 px-6">
                            {project.author.first_name}
                          </td>
                          <td className="py-4 px-6">{project.level}</td>
                          <td className="py-4 px-6">
                            {new Date(
                              project.submitted_date
                            ).toLocaleDateString()}
                          </td>
                          <td className="py-4 px-6">
                            <div className="flex items-center">
                              <Link
                                to={`/account/project/${project.id}`}
                                className="font-medium text-blue-600 hover:underline mr-3"
                              >
                                <MdEdit size={20} />
                              </Link>
                              <DeleteProjectModal
                                id={project.id}
                                removeProject={removeProject}
                              />
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              ) : (
                <div className="my-2">No project found</div>
              )}
            </div>
          </TabPanel>
          <TabPanel>
            <div>
              <ul className="flex space-x-4 border-b">
                <li
                  onClick={() => handleActiveTab("events")}
                  className={`py-2 px-4 cursor-pointer ${
                    activeTab === "events"
                      ? "border-b-2 border-blue-500 text-blue-600 font-semibold"
                      : "text-gray-500 hover:text-gray-700"
                  }`}
                >
                  Events
                </li>
                <li
                  onClick={() => handleActiveTab("videos")}
                  className={`py-2 px-4 cursor-pointer ${
                    activeTab === "videos"
                      ? "border-b-2 border-blue-500 text-blue-600 font-semibold"
                      : "text-gray-500 hover:text-gray-700"
                  }`}
                >
                  Videos
                </li>
              </ul>
            </div>
            <div className="px-4 py-2">
              {activeTab === "events" && (
                <div>
                  <div className="flex items-center justify-between mb-4">
                    <h5 className="text-xl font-semibold text-center ">
                      Events
                    </h5>
                    <CreateEvent addEvent={addEvent} />
                  </div>

                  <div className="mb-4">
                    <ul className="flex border-b">
                      <li
                        className={`mr-1 ${
                          eventTab === "waiting"
                            ? "border-b-2 border-blue-500"
                            : ""
                        }`}
                      >
                        <button
                          className={`bg-white inline-block py-2 px-4 font-semibold ${
                            eventTab === "waiting"
                              ? "text-blue-600"
                              : "text-gray-600 hover:text-blue-600"
                          }`}
                          onClick={() => setEventTab("waiting")}
                        >
                          Waiting Events
                        </button>
                      </li>
                      <li
                        className={`mr-1 ${
                          eventTab === "live"
                            ? "border-b-2 border-blue-500"
                            : ""
                        }`}
                      >
                        <button
                          className={`bg-white inline-block py-2 px-4 font-semibold ${
                            eventTab === "live"
                              ? "text-blue-600"
                              : "text-gray-600 hover:text-blue-600"
                          }`}
                          onClick={() => setEventTab("live")}
                        >
                          Live Events
                        </button>
                      </li>
                    </ul>
                  </div>

                  {eventTab === "waiting" && (
                    <div>
                      {waitingEventsLoading ? (
                        <div class="flex justify-center w-[100%]">
                          <span class="loading loading-bars loading-lg"></span>
                        </div>
                      ) : waitingEvents.length === 0 ? (
                        <p className="text-center text-gray-500 py-8">
                          No waiting events at the moment.
                        </p>
                      ) : (
                        waitingEvents.map((event) => (
                          <div
                            key={event.id}
                            className="bg-white shadow-sm rounded-lg p-4 mb-4 border border-gray-200 hover:shadow-md transition duration-300"
                          >
                            <div className="flex justify-between items-start mb-2">
                              <h3 className="text-lg font-semibold text-gray-800">
                                {event.title}
                              </h3>
                              <span className="text-sm text-gray-500 bg-gray-100 px-2 py-1 rounded-full">
                                {new Date(event.event_time).toLocaleString()}
                              </span>
                            </div>
                            <p
                              className="text-gray-600 mb-4 leading-relaxed"
                              dangerouslySetInnerHTML={{
                                __html: truncateText(event.description),
                              }}
                            ></p>
                            <div className="flex flex-wrap gap-4 items-center">
                              {event.flyer && (
                                <img
                                  src={event.flyer}
                                  alt="Event Flyer"
                                  className="w-32 h-32 object-cover rounded-lg shadow-sm"
                                />
                              )}
                            </div>
                            <div className="mt-3 flex justify-between items-center">
                              {event.link && (
                                <a
                                  href={event.link}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className="inline-flex items-center bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 transition duration-300 shadow-sm"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-5 w-5 mr-2"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                  >
                                    <path d="M11 3a1 1 0 100 2h2.586l-6.293 6.293a1 1 0 101.414 1.414L15 6.414V9a1 1 0 102 0V4a1 1 0 00-1-1h-5z" />
                                    <path d="M5 5a2 2 0 00-2 2v8a2 2 0 002 2h8a2 2 0 002-2v-3a1 1 0 10-2 0v3H5V7h3a1 1 0 000-2H5z" />
                                  </svg>
                                  Event Link
                                </a>
                              )}
                            </div>
                          </div>
                        ))
                      )}
                    </div>
                  )}
                  {eventTab === "live" && (
                    <div>
                      {liveEventsLoading ? (
                        <div class="flex justify-center w-[100%]">
                          <span class="loading loading-bars loading-lg"></span>
                        </div>
                      ) : liveEvents.length === 0 ? (
                        <p className="text-center text-gray-500 py-8">
                          No live events available at the moment.
                        </p>
                      ) : (
                        liveEvents.map((event) => (
                          <div
                            key={event.id}
                            className="bg-white shadow-sm rounded-lg p-4 mb-4 border border-gray-200 hover:shadow-md transition duration-300"
                          >
                            <div className="flex justify-between items-start mb-2">
                              <h3 className="text-lg font-semibold text-gray-800">
                                {event.title}
                              </h3>
                              <span className="text-sm text-gray-500 bg-gray-100 px-2 py-1 rounded-full">
                                {new Date(event.event_time).toLocaleString()}
                              </span>
                            </div>
                            <p
                              className="text-gray-600 mb-4 leading-relaxed"
                              dangerouslySetInnerHTML={{
                                __html: truncateText(event.description),
                              }}
                            ></p>
                            <div className="flex flex-wrap gap-4 items-center">
                              {event.flyer && (
                                <img
                                  src={event.flyer}
                                  alt="Event Flyer"
                                  className="w-32 h-32 object-cover rounded-lg shadow-sm"
                                />
                              )}
                            </div>
                            <div className="mt-3 flex justify-between items-center">
                              {event.link && (
                                <a
                                  href={event.link}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className="inline-flex items-center bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 transition duration-300 shadow-sm"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-5 w-5 mr-2"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                  >
                                    <path d="M11 3a1 1 0 100 2h2.586l-6.293 6.293a1 1 0 101.414 1.414L15 6.414V9a1 1 0 102 0V4a1 1 0 00-1-1h-5z" />
                                    <path d="M5 5a2 2 0 00-2 2v8a2 2 0 002 2h8a2 2 0 002-2v-3a1 1 0 10-2 0v3H5V7h3a1 1 0 000-2H5z" />
                                  </svg>
                                  Event Link
                                </a>
                              )}
                            </div>
                          </div>
                        ))
                      )}
                    </div>
                  )}
                </div>
              )}
              {activeTab === "videos" && (
                <>
                  <div className="flex items-center justify-between">
                    <h5>Videoes</h5>
                    <CreateVideo addVideo={addVideo} />
                  </div>
                  <div className="mt-4">
                    {loading ? (
                      <div class="flex justify-center w-[100%]">
                        <span class="loading loading-bars loading-lg"></span>
                      </div>
                    ) : videos.length === 0 ? (
                      <p>No videos available.</p>
                    ) : (
                      <table className="min-w-full bg-white border border-gray-200 rounded-lg shadow-md">
                        <thead>
                          <tr>
                            <th className="py-3 px-6 bg-gray-100 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">
                              Video
                            </th>
                            <th className="py-3 px-6 bg-gray-100 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">
                              Author
                            </th>
                            <th className="py-3 px-6 bg-gray-100 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">
                              Date
                            </th>
                            <th className="py-3 px-6 bg-gray-100 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">
                              Views
                            </th>
                            <th className="py-3 px-6 bg-gray-100 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">
                              Actions
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {videos.map((video) => (
                            <tr key={video.id} className="bg-gray-50 border-b">
                              <td className="flex items-center py-4 px-6 text-sm text-gray-500">
                                <div>
                                  <img
                                    src={video.poster_image}
                                    alt={video.title}
                                    className="w-12 h-12 rounded-full"
                                  />
                                </div>
                                <div className="ml-3">
                                  <b>{video.title}</b>
                                </div>
                              </td>

                              <td className="py-4 px-6 text-sm text-gray-500">
                                {video.author.email}
                              </td>
                              <td className="py-4 px-6 text-sm text-gray-500">
                                {new Date(video.date).toLocaleDateString()}
                              </td>
                              <td className="py-4 px-6 text-sm text-gray-500">
                                {video.views}
                              </td>
                              <td className="flex items-center py-4 px-6 text-sm text-gray-500">
                                <div className="">
                                  <Link
                                    to={`/account/video/${video.id}`}
                                    className=""
                                  >
                                    <MdEdit size={24} />
                                  </Link>
                                </div>
                                <DeleteVideoModal
                                  id={video.id}
                                  removeVideo={removeVideo}
                                />
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    )}
                  </div>
                </>
              )}
            </div>
          </TabPanel>
        </Tabs>
      </div>
    </Container>
  );
}

export default AccountHome;
