import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "../../../helpers/axios";
import userLogo from "../../../imgs/user_log.png";
import { FaRegCalendarAlt } from "react-icons/fa";
import { IoMdTime } from "react-icons/io";
import { TfiEmail } from "react-icons/tfi";
import { FaLink } from "react-icons/fa6";
import { calculateTimeRemaining } from "../../../utils";

const Event = () => {
  const { id } = useParams();
  const [event, setEvent] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axios
      .get(`/nsangiza/event/${id}/`)
      .then((res) => {
        setLoading(false);
        if (res.data) {
          setEvent(res.data);
        } else {
          console.log("error happen");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        setLoading(false);
      });
  }, [id]);

  const [timeRemaining, setTimeRemaining] = useState(
    calculateTimeRemaining(event?.event_time)
  );

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimeRemaining(calculateTimeRemaining(event?.event_time));
    }, 1000);

    return () => clearInterval(intervalId);
  }, [event?.event_time]);

    console.log(event);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  const formatTime = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
    });
  };

  return (
    <div className="w-[80%]  mx-auto my-10">
      {loading ? (
        <div class="flex justify-center w-[100%]">
          <span class="loading loading-bars loading-lg"></span>
        </div>
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 items-start   ">
          <div className="col-span-2 p-3">
            <div>
              <img
                src={event.flyer}
                alt=""
                className="w-[100%] h-[400px] object-cover border shadow-md"
              />
            </div>
            <div className="mt-4">
              <h1 className="font-semibold">{event.title}</h1>
              <p
                className="mt-2"
                dangerouslySetInnerHTML={{
                  __html: event.description,
                }}
              />

              <div className="mt-4">
                <div className="border-2 border-gray-300 rounded-lg p-4 text-gray-500 flex justify-around">
                  {timeRemaining.ended ? (
                    <div className="text-2xl font-bold">Event has ended</div>
                  ) : (
                    <>
                      <div className="flex flex-col items-center">
                        <span className="text-7xl">{timeRemaining.days}</span>
                        <span className="font-medium text-lg uppercase">
                          Days
                        </span>
                      </div>
                      <div className="flex flex-col items-center">
                        <span className="text-7xl">{timeRemaining.hours}</span>
                        <span className="font-medium text-lg uppercase">
                          HOURS
                        </span>
                      </div>
                      <div className="flex flex-col items-center">
                        <span className="text-7xl">
                          {timeRemaining.minutes}
                        </span>
                        <span className="font-medium text-lg uppercase">
                          MINUTES
                        </span>
                      </div>
                      <div className="flex flex-col items-center">
                        <span className="text-7xl">
                          {timeRemaining.seconds}
                        </span>
                        <span className="font-medium text-lg uppercase">
                          SECONDS
                        </span>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="p-3 border border-gray-300 rounded-lg">
            <div className="bg-gray-100 p-3 flex rounded">
              <div>
                <FaRegCalendarAlt size={25} className="text-blue-500" />
              </div>
              <div className="flex flex-col px-2">
                <span className="font-medium uppercase ">Date</span>{" "}
                <span className="text-gray-500">
                  {formatDate(event.event_time)}
                </span>{" "}
              </div>
            </div>
            <div className="bg-gray-100 p-3 flex rounded mt-3">
              <div>
                <IoMdTime size={25} className="text-blue-500" />
              </div>
              <div className="flex flex-col px-2">
                <span className="font-medium uppercase ">Time</span>{" "}
                <span className="text-gray-500">
                  {formatTime(event.event_time)}
                </span>{" "}
              </div>
            </div>
            <div className="bg-gray-100 p-3 flex rounded mt-3">
              <div>
                <FaLink size={25} className="text-blue-500" />
              </div>
              <div className="flex flex-col px-2">
                <span className="font-medium uppercase ">Event Link</span>{" "}
                <div className="flex flex-col">
                  <a
                    href={event.link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Click to view
                  </a>
                </div>
              </div>
            </div>

            <div className="bg-gray-100 p-3  rounded mt-3">
              {/* <div>
                <img
                  src={userLogo}
                  alt=""
                  className="w-[100%] h-[200px] object-cover"
                />
              </div> */}
              <div className=" pb-2">
                <span className="font-medium uppercase ">ORGANIZER</span>{" "}
              </div>
              <div>
                <div className="flex items-center space-x-2">
                  <div>
                    <FaRegCalendarAlt className="text-blue-500" />
                  </div>
                  <div className="uppercase">Name: </div>
                  <div>{event.user.first_name +  " " + event.user.last_name }</div>
                </div>
                <div className="flex items-center space-x-2 mt-3">
                  <div>
                    <TfiEmail className="text-blue-500" />
                  </div>
                  <div className="uppercase">Email: </div>
                  <div>{event.user.email}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Event;
