import React, { useState } from "react";
import "./styles/footer.css";
import BwengeLogo from "./../imgs/Logowhite.jpg";
// import history from "../Redux/actions/history";
import { FaFacebookF, FaTwitter, FaInstagram, FaYoutube } from "react-icons/fa";
import { FcNext } from "react-icons/fc";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className=" w-[93%] mx-auto mt-5">
      <div className="flex flex-col md:flex-row justify-between items-center  w-full">
        <div className="w-full sm:w-[20%] h-full ">
          <img src={BwengeLogo} alt="Bwenge Logo" className="mr-4" />
        </div>
        <div className="bg-[#2071b8] text-white py-8 w-[100%] sm:w-[80%] ">
          <div className="flex flex-col md:flex-row justify-between ">
            <div className="flex-grow md:text-left border-r-4 border-gray-300 my-4 px-5">
              <h2 className="text-3xl md:text-4xl font-bold mb-2 opacity-75">
                ADDRESS
              </h2>
              <div className="text-xl opacity-75 flex flex-col">
                <span>Kigali</span>
                <span>Rwanda</span>
                <span>Kimironko</span>
                <span>KN-124-Kigali</span>
              </div>
            </div>
            <div className="flex-grow md:text-left border-r-4 border-gray-300 my-4 px-5">
              <h2 className="text-3xl md:text-4xl font-bold mb-2 opacity-75">
                QUICK LINKS
              </h2>
              <span className=" flex flex-col">
                <span>
                  <Link
                    to="/"
                    className="text-xl  opacity-75 no-underline text-white hover:underline"
                  >
                    Home
                  </Link>
                </span>
                <span>
                  <Link
                    to="/about"
                    className="text-xl opacity-75 no-underline text-white hover:underline"
                  >
                    About
                  </Link>
                </span>
                <span>
                  <Link
                    to="/projects"
                    className="text-xl opacity-75 no-underline text-white hover:underline"
                  >
                    Projects
                  </Link>
                </span>
                <span>
                  <Link
                    to="/communities"
                    className="text-xl opacity-75 no-underline text-white hover:underline"
                  >
                    Communities
                  </Link>
                </span>
                <span>
                  <Link
                    to="/courses"
                    className="text-xl opacity-75 no-underline text-white hover:underline"
                  >
                    Courses
                  </Link>
                </span>
                <span>
                  <Link
                    to="/nsangiza"
                    className="text-xl opacity-75 no-underline text-white hover:underline"
                  >
                    Nsangiza
                  </Link>
                </span>
              </span>
            </div>
            <div className="flex-grow text-center md:text-left mt-4 md:mt-0">
              <h2 className="text-3xl md:text-4xl font-bold mb-2 opacity-75">
                STAY CONNECTED
              </h2>
              <div>
                <h1 className="font-semibold text-center text-3xl md:text-3xl text-white opacity-75 mt-5">
                  Join us TODAY
                </h1>
                <div className="my-4">
                  <Link
                    to={"/login"}
                    className="bg-white text-primary  text-lg sm:text-xl md:text-3xl font-semibold rounded-xl px-[40px] py-2  mr-3 no-underline hover:opacity-75"
                  >
                    Member
                  </Link>
                  <Link
                    to={"/signup"}
                    className="bg-white text-primary  text-lg sm:text-xl md:text-3xl font-semibold rounded-xl px-[40px] py-2  mr-3 no-underline hover:opacity-75"
                  >
                    institution
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Footer;
