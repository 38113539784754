import React, { useState, useEffect } from "react";
import time from "../../../imgs/svg/time.svg";
import imageabout from "../../../imgs/nsangiza.png";
import image1 from "../../../imgs/png/n1.png";
import image2 from "../../../imgs/png/n2.png";
import axios from "../../../helpers/axios";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// import { useMutation } from "@apollo/client";
import _ from "lodash";
import { cutString, formatTimeLeft, truncateText } from "../../../utils";
import { WaitingEvent } from "../../WaitingEvent";
import { Link } from "react-router-dom";

const NextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block",
        borderRadius: "50%",
        padding: "0 35px 0 0",
        zIndex: 1,
        transform: "translateX(-100%)",
      }}
      onClick={onClick}
    />
  );
};

const PrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block",
        borderRadius: "50%",
        padding: "10px 50px 0 0",
        zIndex: 1,
        transform: "translateX(100%)",
      }}
      onClick={onClick}
    />
  );
};

const NsangizaMainPage = () => {
  const [liveColor, setLiveColor] = useState("red");

  const [liveEvents, setLiveEvents] = useState([]);
  const [liveEventsLoading, setLiveEventsLoading] = useState(true);
  const [waitingEvents, setWaitingEvents] = useState([]);
  const [waitingEventsLoading, setWaitingEventsLoading] = useState(true);
  const [videos, setVideos] = useState([]);
  const [videosLoading, setVideosLoading] = useState(true);

  //  slide settings

  const settings = {
    dots: false,
    infinite: true,
    speed: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  // get events

  useEffect(() => {
    axios
      .get("/nsangiza/events/live/")
      .then((res) => {
        setLiveEventsLoading(false);
        setLiveEvents(res.data);
      })
      .catch((error) => {
        console.error("Error:", error);
        setLiveEventsLoading(false);
      });
  }, []);

  useEffect(() => {
    axios
      .get("/nsangiza/events/waiting/")
      .then((res) => {
        console.log(res.data);
        setWaitingEventsLoading(false);
        setWaitingEvents(res.data);
      })
      .catch((error) => {
        console.error("Error:", error);
        setWaitingEventsLoading(false);
      });
  }, []);

  useEffect(() => {
    axios
      .get("/nsangiza/video/list")
      .then((res) => {
        console.log("video data", res.data);
        setVideosLoading(false);
        setVideos(res.data);
      })
      .catch((error) => {
        console.error("Error:", error);
        setVideosLoading(false);
      });
  }, []);

  return (
    <div>
      <Slider {...settings}>
        <div className="md:h-[100vh] object-cover ">
          <img src={image1} alt="" srcset="" className="w-full h-full" />
        </div>

        <div className="md:h-[100vh] object-cover">
          <img src={image2} alt="" srcset="" className="w-full h-full " />
        </div>
      </Slider>

      <div className="mx-3 mt-5">
        <div className="live-container">
          <div className="meeting-title">
            <span className="live-title">
              Live <i style={{ color: liveColor }} className="circle icon"></i>
            </span>
          </div>
          <div className="row">
            {liveEventsLoading ? (
              <div className="col-12 text-center">
                <span class="loading loading-bars loading-md"></span>
              </div>
            ) : liveEvents.length === 0 ? (
              <div className="col-12 text-center">
                <p>No live events available at the moment.</p>
              </div>
            ) : (
              liveEvents.map((event) => (
                <div className="col-md-3" key={event.id}>
                  <div className="card meeting-card rounded-lg">
                    <img
                      className="h-32 sm:h-40 md:h-48 lg:h-56 w-full object-cover rounded-t-lg"
                      src={event.flyer}
                      alt={event.title}
                    />
                    <div className="card-body">
                      <h5 className="card-title">
                        <b>{event.title}</b>
                      </h5>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: truncateText(event.description),
                        }}
                      />
                      <br />
                      <div>
                        <div className="join-container">
                          <img src={time} alt="" />
                          <span>
                            {new Date(event.event_time).toLocaleString()}
                          </span>
                          <a
                            href={event.link}
                            className="join-btn"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Join
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
        <div className="live-container">
          <div className="meeting-title">
            <span className="live-title">WAITING</span>
          </div>
          <div className="row">
            {waitingEventsLoading ? (
              <div className="col-12 text-center">
                <span class="loading loading-bars loading-md"></span>
              </div>
            ) : waitingEvents.length === 0 ? (
              <div className="col-12 text-center">
                <p>No waiting events available at the moment.</p>
              </div>
            ) : (
              waitingEvents.map((event) => (
                <Link key={event.id} className="col-md-3 no-underline hover:opacity-75 transation duration-300" to={`/nsangiza/event/${event.id}`}>
                  <WaitingEvent event={event} />
                </Link>
              ))
            )}
          </div>
        </div>
        <div className="live-container">
          <div className="meeting-title">
            <span className="live-title">LATEST VIDEOS</span>
          </div>
          <div className="row">
            {videosLoading ? (
              <div className="col-12 text-center">
                <span className="loading loading-bars loading-md"></span>
              </div>
            ) : videos.length === 0 ? (
              <div className="col-12 text-center">
                <p>No videos available at the moment.</p>
              </div>
            ) : (
              videos.map((video) => (
                <div key={video.id} className="col-md-3">
                  <Link
                    to={`video/${video.id}`}
                    className="card meeting-card p-3 bg-white rounded-lg no-underline text-center text-black"
                  >
                    <div className="position-relative">
                      <div
                        className="position-relative"
                        style={{ height: "0", paddingBottom: "56.25%" }}
                      >
                        <img
                          className="rounded-lg position-absolute w-100 h-100 object-cover"
                          src={video.poster_image}
                          alt={video.title}
                        />
                        <div
                          className="position-absolute rounded-lg top-0 start-0 w-100 h-100"
                          style={{
                            background:
                              "linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5))",
                            pointerEvents: "none",
                            transition: "background 0.3s ease",
                          }}
                        ></div>
                      </div>
                      <div className="position-absolute top-50 start-50 translate-middle">
                        <i className="fas fa-play-circle fa-3x text-white"></i>
                      </div>
                    </div>
                    <div className="my-2">
                      <h3 className="text-center">
                        <b>
                         {cutString(video.title, 20)}
                        </b>
                      </h3>

                      <div
                        dangerouslySetInnerHTML={{
                          __html: truncateText(video.description),
                        }}
                      />
                    </div>
                  </Link>
                  <style jsx>{`
                    .meeting-card:hover .position-absolute.rounded-lg {
                      background: linear-gradient(
                        rgba(0, 0, 0, 0.3),
                        rgba(0, 0, 0, 0.3)
                      ) !important;
                    }
                  `}</style>{" "}
                </div>
              ))
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NsangizaMainPage;
