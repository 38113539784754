import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "../../../helpers/axios";
import { GoBack } from "../../GoBack";

const EditProject = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [updating, setUpdating] = useState(false);
  const [topic, setTopic] = useState("");
  const [description, setDescription] = useState("");
  const [tags, setTags] = useState("");
  const [level, setLevel] = useState("");
  const [projectFile, setProjectFile] = useState(null);
  const [previewFile, setPreviewFile] = useState(null);
  const [institution, setInstitution] = useState("");
  const [institutions, setInstitutions] = useState([]);
  const [project, setProject] = useState(null);
  const [userInfo, setUserInfo] = useState({});

  const navigate = useNavigate();
  // redirect back to login
  useEffect(() => {
    const storedUserInfo = localStorage.getItem("userInfo");

    if (storedUserInfo) {
      const parsedUserInfo = JSON.parse(storedUserInfo); // Parse only once
      if (!parsedUserInfo?.userEmail) {
        navigate("/login");
      } else {
        setUserInfo(parsedUserInfo);
      }
    } else {
      navigate("/login");
    }
  }, [navigate]);

  useEffect(() => {
    const fetchProject = async () => {
      try {
        const response = await axios.get(`/projects/${id}/`);
        const data = response.data;
        setProject(data);
        setTopic(data.topics);
        setDescription(data.description);
        setTags(data.tags);
        setLevel(data.level);
        setInstitution(data.institution);
        setPreviewFile(data.file);
        setProjectFile(data.file);
      } catch (error) {
        console.error("Error fetching project:", error);
        toast.error("Failed to fetch project details.");
      } finally {
        setLoading(false);
      }
    };

    fetchProject();
  }, [id]);

  useEffect(() => {
    axios
      .get("/institutions/")
      .then((res) => {
        setInstitutions(res.data);
      })
      .catch((error) => {
        console.error("Error fetching institutions:", error);
      });
  }, []);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setProjectFile(file);
    // Check if it's an image file to preview it
    if (file && file.type.startsWith("image/")) {
      const reader = new FileReader();
      reader.onload = () => {
        setPreviewFile(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      setPreviewFile(null);
    }
  };

  const handleUpdateProject = async (e) => {
    e.preventDefault();
    setUpdating(true);
    const formData = new FormData();
    formData.append("topics", topic);
    formData.append("description", description);
    formData.append("tags", tags);
    formData.append("level", level);
    formData.append("institution", institution);
    formData.append("user_id", userInfo.userId);
    formData.append("file", projectFile);
    if (projectFile) {
    }

    try {
      const response = await axios.put(`/projects/${id}/edit/`, formData);
      // console.log("response", response);
      if (response?.data) {
        toast.success("Project updated successfully");
      } else {
        toast.error("Failed to update project.");
      }
    } catch (error) {
      console.error("Error updating project:", error);
      toast.error("Failed to update project.");
    } finally {
      setUpdating(false);
    }
  };

  if (loading) {
    return (
      <div class="flex justify-center w-[100%]">
        <span class="loading loading-bars loading-lg"></span>
      </div>
    );
  }

  return (
    <div className="container mx-auto my-5">
      <GoBack />
      <ToastContainer />
      <h1 className="text-center text-3xl font-bold">Edit Project</h1>
      <form
        onSubmit={handleUpdateProject}
        className="mt-5 p-5 border rounded shadow w-full sm:w-[90%] md:w-[80%] lg:w-[70%] xl:w-[60%] mx-auto"
      >
        <div className="mb-4">
          <label className="block mb-2">Topic:</label>
          <input
            type="text"
            name="topic"
            value={topic}
            onChange={(e) => setTopic(e.target.value)}
            className="w-full p-2 border rounded"
            required
          />
        </div>
        <div className="mb-4">
          <label className="block mb-2">Description:</label>
          <textarea
            name="description"
            rows="3"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            className="w-full p-2 border rounded"
            required
          />
        </div>
        <div className="mb-4">
          <label className="block mb-2">File:</label>
          <input
            type="file"
            name="projectFile"
            onChange={handleFileChange}
            className="w-full p-2 border rounded"
          />

          {previewFile && (
            <div className="mt-2">
              {projectFile?.type?.startsWith("image/") ? (
                <img
                  src={previewFile}
                  alt="Preview"
                  className="max-w-full h-auto border rounded shadow-md"
                />
              ) : (
                <a
                  href={previewFile}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-600 underline"
                >
                  View File
                </a>
              )}
            </div>
          )}
        </div>
        <div className="mb-4">
          <label className="block mb-2">Tags (separate with commas):</label>
          <input
            type="text"
            name="tags"
            value={tags}
            onChange={(e) => setTags(e.target.value)}
            className="w-full p-2 border rounded"
          />
        </div>
        <div className="mb-4">
          <label className="block mb-2">Level:</label>
          <select
            name="level"
            value={level}
            onChange={(e) => setLevel(e.target.value)}
            className="w-full p-2 border rounded"
            required
          >
            <option value="" disabled>
              Select Level
            </option>
            <option value="Bachelor">Bachelor</option>
            <option value="Masters">Masters</option>
            <option value="PhD">PhD</option>
          </select>
        </div>
        <div className="mb-4">
          <label className="block mb-2">Institution:</label>
          <select
            name="institution"
            value={institution}
            onChange={(e) => setInstitution(e.target.value)}
            className="w-full p-2 border rounded"
            required
          >
            <option value="">Select Institution</option>
            {institutions.map((inst) => (
              <option key={inst.id} value={inst.id}>
                {inst.name}
              </option>
            ))}
          </select>
        </div>
        <button
          type="submit"
          className="bg-blue-500 text-white px-4 py-2 rounded"
          disabled={updating}
        >
          {updating ? "Updating..." : "Update Project"}
        </button>
      </form>
    </div>
  );
};

export default EditProject;
