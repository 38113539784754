import React, { useState } from "react";
import { Link } from "react-router-dom";
import { ProjectPagination } from "./ProjectPagination";

export const ProjectTable = ({ projects }) => {
  const [currentPage, setCurentPage] = useState(1);
  const [projectsPerPage] = useState(10);

  const indexOfLastProject = currentPage * projectsPerPage;
  const indexOfFirstProject = indexOfLastProject - projectsPerPage;
  const currentProjects = projects.slice(
    indexOfFirstProject,
    indexOfLastProject
  );

  return (
    <>
      <table className="table table-striped table-hover shadow-sm">
        <thead className="thead-dark">
          <tr>
            <th className="px-4 py-2">Title</th>
            <th className="px-4 py-2">Authors</th>
            <th className="px-4 py-2">Level</th>
            <th className="px-4 py-2">Submission Date</th>
            <th className="px-4 py-2">Downloads</th>

            <th className="px-4 py-2">Action</th>
          </tr>
        </thead>
        <tbody>
          {currentProjects.map((project) => (
            <tr key={project.id}>
              <td className="px-4 py-2">{project.topics}</td>
              <td className="px-4 py-2">{`${project.author.first_name} ${project.author.last_name}`}</td>
              <td className="px-4 py-2">{project.level}</td>
              <td className="px-4 py-2">
                {new Date(project.submitted_date).toLocaleDateString()}
              </td>
              <td className="px-4 py-2">{project.total_downloads}</td>
              <td className="px-4 py-2">
                <Link
                  to={`/project/${project.id}`}
                  className="btn btn-primary btn-sm"
                >
                  View
                </Link>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="mt-4">
        <ProjectPagination
          projectsPerPage={projectsPerPage}
          totalProjects={projects.length}
          paginate={setCurentPage}
        />
      </div>
    </>
  );
};
