import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "../styles/auth.css";
import axios from "../../helpers/axios";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { signupSchema, institutionSchema } from "../validations/userSchema";
import { yupResolver } from "@hookform/resolvers/yup";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SignupUserImage from "../../imgs/svg/PICS-BWENGE-66.svg";
import SignupInstitImage from "../../imgs/svg/PICS-BWENGE-77.svg";
import { MdOutlineKeyboardDoubleArrowRight } from "react-icons/md";

const SignupForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    password: "",
    confirmPassword: "",
  });
  const [logging, setLogging] = useState(false);
  const [error, setError] = useState("");
  const [active, setActive] = useState("user");
  const [hovered, setHovered] = useState(null);
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors: userErrors },
  } = useForm({
    resolver: yupResolver(signupSchema),
  });

  const {
    register: registerInstitution,
    handleSubmit: handleSubmitInstitution,
    formState: { errors: InstitutionErrors },
  } = useForm({
    resolver: yupResolver(institutionSchema),
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.id]: e.target.value,
    });
  };

  const onSubmit = async (data) => {
    setLogging(true);
    await fetch("https://admin.bwenge.com/api/auth/register/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: data.email,
        telephone: data.phone,
        first_name: data.fname,
        last_name: data.lname,
        password: data.password,
        password2: data.confirmPassword,
      }),
    })
      .then((response) => {
        setLogging(false);
        return response.json();
      })
      .then((res) => {
        // console.log("get here", res);
        if (res.email || res.telephone) {
          if (res.email) {
            toast.error(
              res.email[0] || "Unexpected email error, try again later"
            );
          }
          if (res.telephone) {
            toast.error(
              res.telephone[0] || "Unexpected telephone error, try again later"
            );
          }
        } else {
          toast.success("Registration successful!");
          setTimeout(() => {
            navigate("/verify");
          }, 1000);
        }
      })
      .catch((err) => {
        setLogging(false);
        toast.error("Unexpected error, try again later");
      });
  };

  const onSubmitInstitution = async (data) => {
    setLogging(true);
    await fetch("https://admin.bwenge.com/api/auth/register/instution/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        first_name: data.name,
        email: data.email,
        telephone: data.phone,
        password: data.password,
        password2: data.confirmPassword,
      }),
    })
      .then((response) => {
        setLogging(false);
        return response.json();
      })
      .then((res) => {
        if (res.email || res.telephone) {
          if (res.email) {
            toast.error(
              res.email[0] || "Unexpected email error, try again later"
            );
          }
          if (res.telephone) {
            toast.error(
              res.telephone[0] || "Unexpected telephone error, try again later"
            );
          }
        } else {
          toast.success("Institution registration successful!");
          setTimeout(() => {
            navigate("/verify");
          }, 1000);
        }
      })
      .catch((err) => {
        setLogging(false);
        toast.error("Unexpected error, try again later");
      });
  };

  return (
    <div
      className={`${
        active === "user" ? "signup-container" : "institute-container"
      }`}
    >
      <ToastContainer />
      <div
        className={`button-container w-[95%] ${
          active !== "user" ? "" : " md:w-[75%]"
        }`}
      >
        <button
          className={`relative flex items-center rounded-full bg-yellow-400 text-black text-xl md:text-2xl font-medium  h-16 transition-all duration-300 ease-in-out ${
            active === "user" ? "active text-white" : ""
          }`}
          onClick={() => setActive("user")}
          onMouseEnter={() => setHovered("user")}
          onMouseLeave={() => setHovered(null)}
        >
          Register as User
          {/* Conditionally show icon on hover, but not when active */}
          {hovered === "user" && active !== "user" && (
            <MdOutlineKeyboardDoubleArrowRight
              size={40}
              className="ml-2 text-white bg-yellow-500 rounded-full p-1 transition-opacity duration-300 opacity-100"
            />
          )}
        </button>

        <button
          className={`relative flex items-center rounded-full bg-yellow-400 text-black text-xl md:text-2xl font-medium  h-16 transition-all duration-300 ease-in-out ${
            active === "institution" ? "active text-white" : ""
          }`}
          onClick={() => setActive("institution")}
          onMouseEnter={() => setHovered("institution")}
          onMouseLeave={() => setHovered(null)}
        >
          Register as Institution
          {/* Conditionally show icon on hover, but not when active */}
          {hovered === "institution" && active !== "institution" && (
            <MdOutlineKeyboardDoubleArrowRight
              size={40}
              className="ml-2 text-white bg-yellow-500 rounded-full p-1 transition-opacity duration-300 opacity-100"
            />
          )}
        </button>
      </div>
      <div
        className={`w-full  mx-auto flex flex-col items-center   ${
          active !== "user" ? "md:items-center" : "md:w-[85%] md:items-end"
        }`}
      >
        <div className="bg-white text-center text-gray-400 py-3 w-[95%] md:w-[75%] rounded-[10px] my-3 font-medium text-2xl">
          {active === "user" ? (
            <div>
              <span className="font-bold"> Enter your personal details,</span>{" "}
              start learning and sharing today.
            </div>
          ) : (
            <div>
              <span className="font-bold">
                {" "}
                Enter the following details about your esteemed institution.
              </span>{" "}
            </div>
          )}
        </div>
        <div className="login-content bg-white w-[95%] md:w-[75%] md:flex mb-5 md:mb-1">
          {active === "user" ? (
            <>
              <div className="login-description  p-5 md:p-10 w-full md:w-[30%]  flex items-center justify-center ">
                <div>
                  <div className="text-lg font-semibold">
                    DUSHAKASHAKE DUSHAKIRA U RWANDA
                  </div>
                  <img src={SignupUserImage} alt="" className="my-3" />
                  <p>Join us, learn and share your knowledge to the world.</p>
                </div>
              </div>
              <div className=" login-form p-10 w-full md:w-[70%] ">
                <h2>User Sign Up Now!</h2>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="form-group">
                    <div className="row">
                      <div className="col-md-6">
                        <label htmlFor="first_name">First Name</label>
                        <input
                          type="text"
                          id="first_name"
                          placeholder="Enter your first name"
                          onChange={handleChange}
                          defaultValue={formData.first_name}
                          {...register("fname")}
                        />
                        {userErrors.fname && (
                          <span className="errorMsg text-danger text-sm">
                            {userErrors.fname?.message}
                          </span>
                        )}
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="last_name">Last Name</label>
                        <input
                          type="text"
                          id="last_name"
                          placeholder="Enter your last name"
                          onChange={handleChange}
                          defaultValue={formData.last_name}
                          {...register("lname")}
                        />
                        {userErrors.lname && (
                          <span className="errorMsg text-danger text-sm">
                            {userErrors.lname?.message}
                          </span>
                        )}
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="phone">Phone Number</label>
                        <input
                          type="text"
                          id="phone"
                          placeholder="Enter your phone"
                          onChange={handleChange}
                          defaultValue={formData.phone}
                          {...register("phone")}
                        />
                        {userErrors.phone && (
                          <span className="errorMsg text-danger text-sm">
                            {userErrors.phone?.message}
                          </span>
                        )}
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="email">Email</label>
                        <input
                          type="email"
                          id="email"
                          placeholder="Enter your email"
                          defaultValue={formData.email}
                          {...register("email")}
                        />
                        {userErrors.email && (
                          <span className="errorMsg text-danger text-sm">
                            {userErrors.email?.message}
                          </span>
                        )}
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="password">Password</label>
                        <input
                          type="password"
                          id="password"
                          placeholder="Enter your password"
                          onChange={handleChange}
                          defaultValue={formData.password}
                          {...register("password")}
                        />
                        {userErrors.password && (
                          <span className="errorMsg text-danger text-sm">
                            {userErrors.password?.message}
                          </span>
                        )}
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="confirmPassword">
                          Confirm Password
                        </label>
                        <input
                          type="password"
                          id="confirmPassword"
                          placeholder="Confirm password"
                          onChange={handleChange}
                          defaultValue={formData.confirmPassword}
                          {...register("confirmPassword")}
                        />
                        {userErrors.confirmPassword && (
                          <span className="errorMsg text-danger text-sm">
                            {userErrors.confirmPassword?.message}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                  {logging ? (
                    <p>Sending...</p>
                  ) : (
                    error && <p className="text-danger">{error}</p>
                  )}
                  <button type="submit" className="login-btn">
                    Sign Up
                  </button>
                </form>
                <div className="login-options">
                  <span>
                    Already have an account? <Link to="/login">Login</Link>
                  </span>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="login-description  p-5 md:p-10 w-full md:w-[30%] md:h-[100%] flex items-center justify-center h-full">
                <div>
                  <div className="text-lg font-semibold">
                    DUSHAKASHAKE DUSHAKIRA U RWANDA
                  </div>
                  <img src={SignupInstitImage} alt="" className="my-3" />
                  <p>Join us, learn and share your knowledge to the world.</p>
                </div>
              </div>
              <div className="login-form p-10 w-full md:w-[70%] ">
                <h2>Institution Sign Up Now!</h2>
                <form onSubmit={handleSubmitInstitution(onSubmitInstitution)}>
                  <div className="form-group">
                    <div className="row">
                      <div className="col-md-12 mt-2">
                        <label htmlFor="name">Institution Name</label>
                        <input
                          type="text"
                          id="name"
                          placeholder="Enter institution name"
                          onChange={handleChange}
                          defaultValue={formData.name}
                          {...registerInstitution("name")}
                        />
                        {InstitutionErrors.name && (
                          <span className="errorMsg text-danger text-sm">
                            {InstitutionErrors.name?.message}
                          </span>
                        )}
                      </div>

                      <div className="col-md-6 mt-2">
                        <label htmlFor="phone">Phone Number</label>
                        <input
                          type="text"
                          id="phone"
                          placeholder="Enter your phone"
                          onChange={handleChange}
                          defaultValue={formData.phone}
                          {...registerInstitution("phone")}
                        />
                        {InstitutionErrors.phone && (
                          <span className="errorMsg text-danger text-sm">
                            {InstitutionErrors.phone?.message}
                          </span>
                        )}
                      </div>
                      <div className="col-md-6 mt-2">
                        <label htmlFor="email">Email</label>
                        <input
                          type="email"
                          id="email"
                          placeholder="Enter your email"
                          onChange={handleChange}
                          defaultValue={formData.email}
                          {...registerInstitution("email")}
                        />
                        {InstitutionErrors.email && (
                          <span className="errorMsg text-danger text-sm">
                            {InstitutionErrors.email?.message}
                          </span>
                        )}
                      </div>
                      <div className="col-md-6 mt-2">
                        <label htmlFor="password">Password</label>
                        <input
                          type="password"
                          id="password"
                          placeholder="Enter your password"
                          onChange={handleChange}
                          defaultValue={formData.password}
                          {...registerInstitution("password")}
                        />
                        {InstitutionErrors.password && (
                          <span className="errorMsg text-danger text-sm">
                            {InstitutionErrors.password?.message}
                          </span>
                        )}
                      </div>
                      <div className="col-md-6 mt-2">
                        <label htmlFor="confirmPassword">
                          Confirm Password
                        </label>
                        <input
                          type="password"
                          id="confirmPassword"
                          placeholder="Confirm password"
                          onChange={handleChange}
                          defaultValue={formData.confirmPassword}
                          {...registerInstitution("confirmPassword")}
                        />
                        {InstitutionErrors.confirmPassword && (
                          <span className="errorMsg text-danger text-sm">
                            {InstitutionErrors.confirmPassword?.message}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                  {logging ? <p>Sending...</p> : error && <p>{error}</p>}
                  <button type="submit" className="login-btn">
                    Sign Up
                  </button>
                </form>
                <div className="login-options">
                  <span>
                    Already have an account? <Link to="/login">Login</Link>
                  </span>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default SignupForm;
