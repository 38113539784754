import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "../../helpers/axios";
import DOMPurify from "dompurify";
import { BiSolidLike } from "react-icons/bi";
import { FaComment } from "react-icons/fa";
import { formatDate, formatViews } from "../../utils";
import { LoginModal } from "../LoginModal";
import { toast, ToastContainer } from "react-toastify";
import { FaEye } from "react-icons/fa";
const ArticlePage = () => {
  const { id } = useParams();
  const [article, setArticle] = useState(null);
  const [comment, setComment] = useState("");
  const [showCommentInput, setShowCommentInput] = useState(false);
  const [comments, setComments] = useState([]);
  const [commentLoading, setCommentLoading] = useState(false);
  const [commentError, setCommentError] = useState("");
  const [userInfo, setUserInfo] = useState({});
  const [isLogin, setIsLogin] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [likes, setLikes] = useState([]);

  // check login

  useEffect(() => {
    const savedUserInfo = localStorage.getItem("userInfo");
    if (savedUserInfo) {
      setUserInfo(JSON.parse(savedUserInfo));
    }
  }, [setUserInfo]);

  useEffect(() => {
    if (userInfo?.userEmail) {
      setIsLogin(true);
    }
  }, [userInfo]);

  useEffect(() => {
    const fetchArticle = async () => {
      try {
        const response = await axios.get(`/article/${id}/`);
        // console.log("Article data:", response.data);
        setArticle(response.data);
        setLikes(response.data.likes);
        setComments(response.data.comments);
      } catch (error) {
        console.error("Error fetching article:", error);
      }
    };

    fetchArticle();
  }, [id]);

  const handleCommentChange = (e) => {
    setComment(e.target.value);
  };

  if (!article) {
    return (
      <div className="container my-10">
        <div class="flex justify-center w-[100%]">
          <span class="loading loading-bars loading-lg"></span>
        </div>
      </div>
    );
  }

  const handleLike = () => {
    if (isLogin) {
      // handle like logic here

      const formData = new FormData();
      formData.append("article_id", article.id);
      formData.append("user_id", userInfo.userId);

      axios
        .post(`/article/like/`, formData)
        .then((res) => {
          if (res?.data) {
            setLikes(res.data.likes);
          } else {
            toast.error("Like failed");
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    } else {
      setShowModal(true);
    }
  };

  const handleCommentSubmit = async () => {
    if (isLogin) {
      if (comment.trim() === "") {
        setCommentError("Comment cannot be empty");
        return;
      } else {
        setCommentError("");
      }

      const formData = new FormData();

      formData.append("article", article.id);
      formData.append("comment", comment);
      formData.append("user_id", parseInt(userInfo.userId, 10));

      setCommentLoading(true);
      await axios
        .post("/add-comments/", formData)
        .then((res) => {
          // console.log("res", res);
          if (res.data) {
            toast.success("Comment added successfully");
            setComment("");
            setShowCommentInput(false);
            setCommentError("");
            setComments([...comments, res.data]);
          } else {
            toast.error("Something went wrong");
          }
        })
        .catch((error) => {
          console.log("error", error);
        })
        .finally(() => {
          setCommentLoading(false);
        });
    } else {
      setShowModal(true);
    }
  };

  // const handle
  return (
    <div className="container my-5">
      {!isLogin && showModal && (
        <LoginModal
          setIsLogin={setIsLogin}
          onClose={() => setShowModal(false)}
          content={"You need to login before proceeding"}
        />
      )}
      {/* <h1 className="mb-4">Article</h1> */}
      <div className="row ">
        <ToastContainer />
        <div className="col-md-8 offset-md-2">
          <div className="">
            <h1 className="card-title font-bold text-3xl sm:text-4xl md:text-5xl ">
              {article.title}
            </h1>

            {/* author */}
            <div>
              <div className="flex items-center  py-4">
                <div class="avatar">
                  <div class="w-12 rounded-full">
                    <img src="https://res.cloudinary.com/dqy4dps7q/image/upload/v1717308706/user_ye5vmx.png" />
                  </div>
                </div>
                <div className="ml-2 ">
                  <div className="text-lg font-medium">
                    By{" "}
                    <span className="capitalize">
                      {`${article.author?.first_name} ${article.author?.last_name}`}
                    </span>
                  </div>
                  <div>{formatDate(article.date)}</div>
                </div>
              </div>
            </div>
            {/* like and comment */}
            <div className="flex items-center  inline-block mb-3 space-x-2 ">
              <div
                className="flex items-center  cursor-pointer mb-1"
                onClick={handleLike}
              >
                <BiSolidLike className="text-2xl" />
                <span className="text-base ml-1"> {likes} </span>
              </div>
              <div>
                <div className="drawer drawer-end">
                  <input
                    id="my-drawer-4"
                    type="checkbox"
                    className="drawer-toggle"
                  />
                  <div className="drawer-content">
                    <label htmlFor="my-drawer-4" className="drawer-button">
                      <div className="flex items-center cursor-pointer">
                        <FaComment className="text-2xl" />
                        <span className="text-base ml-1">
                          {" "}
                          {comments.length}{" "}
                        </span>
                      </div>
                    </label>
                  </div>
                  <div className="drawer-side">
                    <label
                      htmlFor="my-drawer-4"
                      aria-label="close sidebar"
                      className="drawer-overlay"
                    ></label>
                    <div className="menu bg-white text-base-content min-h-full w-[70%] md:w-[27%] p-6 shadow-lg rounded-lg">
                      <h1 className="font-semibold text-2xl mb-6 border-b pb-3">
                        Comments{" "}
                        <span className="text-gray-500">
                          ( {comments.length} )
                        </span>
                      </h1>

                      <div className="mb-6">
                        <div>
                          <textarea
                            className="w-full p-3 border rounded-lg resize-none focus:outline-none focus:ring-2 focus:ring-blue-500"
                            placeholder="Write your comment..."
                            rows="4"
                            onChange={handleCommentChange}
                            value={comment}
                          ></textarea>
                          {commentError && (
                            <p className="text-red-500 text-sm mt-1">
                              {commentError}
                            </p>
                          )}
                          <div className="flex justify-end mt-3">
                            <button
                              className="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded-lg transition duration-300 ease-in-out flex items-center"
                              onClick={handleCommentSubmit}
                              disabled={commentLoading}
                            >
                              {commentLoading ? (
                                <>
                                  <svg
                                    className="animate-spin h-5 w-5 mr-3"
                                    viewBox="0 0 24 24"
                                  >
                                    <circle
                                      className="opacity-25"
                                      cx="12"
                                      cy="12"
                                      r="10"
                                      stroke="currentColor"
                                      strokeWidth="4"
                                    ></circle>
                                    <path
                                      className="opacity-75"
                                      fill="currentColor"
                                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                    ></path>
                                  </svg>
                                  Loading...
                                </>
                              ) : (
                                "Comment"
                              )}
                            </button>
                          </div>
                        </div>
                      </div>
                      {[...comments].reverse().map((comment, index) => (
                        <div className="space-y-2" key={index}>
                          <div className="bg-gray-50 p-4 rounded-lg">
                            <div className="flex items-center py-2">
                              <div className="avatar">
                                <div className="w-10 h-10 rounded-full overflow-hidden">
                                  <img
                                    src="https://res.cloudinary.com/dqy4dps7q/image/upload/v1717308706/user_ye5vmx.png"
                                    alt="User Avatar"
                                    className="w-full h-full object-cover"
                                  />
                                </div>
                              </div>
                              <div className="ml-3">
                                <div className="font-medium">
                                  By{" "}
                                  <span className="text-blue-600">{`${comment.author.first_name}`}</span>
                                </div>
                                <div className="text-sm text-gray-500">
                                  {formatDate(comment.date)}
                                </div>
                              </div>
                            </div>

                            <div className="mt-2 text-gray-700">
                              {comment.comment}
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>

              <div className="mb-1 flex items-center ">
                <FaEye className="text-2xl" />
                <span className="ml-1">{formatViews(article.views)}</span>
              </div>
            </div>
            {/* end like and comment */}
            {/* article image  */}
            <div className="mb-3">
              <img
                src={article.poster_image}
                className="w-full h-[200px] sm:h-[300px] md:h-[400px]  object-contain"
                alt={article.title}
              />
            </div>
            <div className="card-body">
              <p
                className="text-lg"
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(article.description),
                }}
              />
            </div>

            {/* likes */}
            <div className="flex items-center  inline-block mb-3 mt-4 space-x-2">
              <div
                className="flex items-center  cursor-pointer mb-1 "
                onClick={handleLike}
              >
                <BiSolidLike className="text-2xl" />
                <span className="text-base ml-1"> {likes} </span>
              </div>
              <div>
                <div className="drawer drawer-end">
                  <input
                    id="my-drawer-4"
                    type="checkbox"
                    className="drawer-toggle"
                  />
                  <div className="drawer-content">
                    <label htmlFor="my-drawer-4" className="drawer-button">
                      <div className="flex items-center cursor-pointer">
                        <FaComment className="text-2xl" />
                        <span className="text-base ml-1">
                          {" "}
                          {comments.length}{" "}
                        </span>
                      </div>
                    </label>
                  </div>
                  <div className="drawer-side">
                    <label
                      htmlFor="my-drawer-4"
                      aria-label="close sidebar"
                      className="drawer-overlay"
                    ></label>
                    <div className="menu bg-white text-base-content min-h-full w-[70%] md:w-[27%] p-6 shadow-lg rounded-lg">
                      <h1 className="font-semibold text-2xl mb-6 border-b pb-3">
                        Comments{" "}
                        <span className="text-gray-500">
                          ( {comments.length} )
                        </span>
                      </h1>

                      <div className="mb-6">
                        <div>
                          <textarea
                            className="w-full p-3 border rounded-lg resize-none focus:outline-none focus:ring-2 focus:ring-blue-500"
                            placeholder="Write your comment..."
                            rows="4"
                            onChange={handleCommentChange}
                            value={comment}
                          ></textarea>
                          {commentError && (
                            <p className="text-red-500 text-sm mt-1">
                              {commentError}
                            </p>
                          )}
                          <div className="flex justify-end mt-3">
                            <button
                              className="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded-lg transition duration-300 ease-in-out flex items-center"
                              onClick={handleCommentSubmit}
                              disabled={commentLoading}
                            >
                              {commentLoading ? (
                                <>
                                  <svg
                                    className="animate-spin h-5 w-5 mr-3"
                                    viewBox="0 0 24 24"
                                  >
                                    <circle
                                      className="opacity-25"
                                      cx="12"
                                      cy="12"
                                      r="10"
                                      stroke="currentColor"
                                      strokeWidth="4"
                                    ></circle>
                                    <path
                                      className="opacity-75"
                                      fill="currentColor"
                                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                    ></path>
                                  </svg>
                                  Loading...
                                </>
                              ) : (
                                "Comment"
                              )}
                            </button>
                          </div>
                        </div>
                      </div>
                      {[...comments].reverse().map((comment, index) => (
                        <div className="space-y-2" key={index}>
                          <div className="bg-gray-50 p-4 rounded-lg">
                            <div className="flex items-center py-2">
                              <div className="avatar">
                                <div className="w-10 h-10 rounded-full overflow-hidden">
                                  <img
                                    src="https://res.cloudinary.com/dqy4dps7q/image/upload/v1717308706/user_ye5vmx.png"
                                    alt="User Avatar"
                                    className="w-full h-full object-cover"
                                  />
                                </div>
                              </div>
                              <div className="ml-3">
                                <div className="font-medium">
                                  By{" "}
                                  <span className="text-blue-600">{`${comment.author.first_name}`}</span>
                                </div>
                                <div className="text-sm text-gray-500">
                                  {formatDate(comment.date)}
                                </div>
                              </div>
                            </div>

                            <div className="mt-2 text-gray-700">
                              {comment.comment}
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              <div className="mb-1 flex items-center ">
                <FaEye className="text-2xl" />
                <span className="ml-1">{formatViews(article.views)}</span>
              </div>
            </div>
            {/* <div className="card-footer">
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <FontAwesomeIcon icon={faThumbsUp} className="mr-2" /> Like
                  <span className="mx-3">|</span>
                  <FontAwesomeIcon
                    icon={faComment}
                    className="mr-2"
                    onClick={() => setShowCommentInput(true)}
                    style={{ cursor: "pointer" }}
                  />{" "}
                  Comment
                </div>
                {showCommentInput && (
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Write your comment..."
                      value={comment}
                      onChange={handleCommentChange}
                    />
                    <div className="input-group-append">
                      <button
                        className="btn btn-outline-secondary"
                        type="button"
                        onClick={handleSubmitComment}
                      >
                        <FontAwesomeIcon icon={faPaperPlane} />
                      </button>
                    </div>
                  </div>
                )}
              </div>
              <small className="text-muted">
                Posted on {formatDate(article.date)}
              </small>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ArticlePage;
