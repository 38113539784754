import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "../styles/auth.css";
import axios from "../../helpers/axios";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { loginSchema, adminLoginSchema } from "../validations/userSchema";
import { yupResolver } from "@hookform/resolvers/yup";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import loginImage from "../../imgs/svg/PICS-BWENGE-55.svg";

const Login = ({ setAuth }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [adminEmail, setaAdminEmail] = useState("");
  const [adminPassword, setaAdminPassword] = useState("");
  const [logging, setLogging] = useState(false);
  const [error, setError] = useState(null);
  const [active, setActive] = useState("user");
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors: userErrors },
  } = useForm({
    resolver: yupResolver(loginSchema),
  });

  const {
    register: registerAdmin,
    handleSubmit: handleSubmitAdmin,
    formState: { errors: adminErrors },
  } = useForm({
    resolver: yupResolver(adminLoginSchema),
  });

  const onSubmit = async (data) => {
    setError(null);
    try {
      setLogging(true);
      axios
        .post("/auth/login/", {
          email: data.email,
          password: data.password,
        })
        .then((res) => {
          // console.log("res ", res);
          if (res?.data?.data?.tokens?.access_token) {
            const userInfo = {
              access_token: res?.data?.data?.access_token,
              refresh_token: res?.data?.data?.refresh_token,
              userEmail: res?.data?.data?.user?.email,
              fullName: res?.data?.data?.user?.full_name,
              phone: res?.data?.data?.user?.telephone,
              role: res?.data?.data?.user?.role,
              userId: res?.data?.data?.user?.id,
              status: res.data.status,
            };
            localStorage.setItem("userInfo", JSON.stringify(userInfo));
            setAuth(userInfo);
            // console.log("userInfo: ", userInfo);
            setTimeout(() => {
              if (userInfo.role === "user") {
                // console.log("user data", res?.data);
                window.location.href = "/account/home";
              } else if (userInfo.role === "institution_admin") {
                window.location.href = "/account/institution";
              } else {
                window.location.href = "/";
              }
            }, 200);
          } else {
            toast.error(res?.data?.message || "Email or password is invalid.");
            // setError(res?.data?.message || "Invalid credentials");
          }
          setLogging(false);
        })
        .catch((error) => {
          setLogging(false);
          if (error.response) {
            setError(
              error.response?.data?.message ||
                "An error occurred. Please try again."
            );
          } else if (error.request) {
            setError(
              "Failed to connect to the server. Please check your internet connection and try again."
            );
          } else {
            setError(
              error.message ||
                "An unexpected error occurred. Please try again later."
            );
          }
        });
    } catch (error) {
      setError("Invalid email or password. Please try again.");
      console.error("Login error:", error);
    }
  };

  return (
    <div className="login-container ">
      <ToastContainer />
      {/* <div className="">
        <button
          className={`bg-mainColor rounded px-5 py-3 text-lg font-medium text-white ${
            active === "user" ? "active" : ""
          }`}
          onClick={() => setActive("user")}
        >
          Login
        </button>
      </div> */}

      <div className=" w-full md:w-[85%] mx-auto flex flex-col items-center md:items-end ">
        <div className="bg-white text-center text-gray-400 py-3 w-[95%] md:w-[65%] rounded-[10px] mb-3 font-medium text-2xl">
          <span className="font-bold"> It’s a pleasure to have you with us</span> , continue learning
          and sharing!
        </div>
        <div className="login-content w-[95%] md:w-[65%] md:flex mb-5 md:mb-1 py-4">
          <div className="login-description p-5 md:p-10 w-full md:w-[40%] md:h-[100%]   justify-center  flex items-center">
            <div>
              <div className="text-lg font-semibold">
                DUSHAKASHAKE DUSHAKIRA U RWANDA
              </div>
              <img src={loginImage} alt="" className="my-3" />
              <p>
                Welcome Back, Resume your journey with us and remember to learn
                and share something new!
              </p>
            </div>
          </div>
          <div className="login-form p-10 w-full md:w-[60%]">
            {active === "user" && <h2 className="">Welcome Back! </h2>}
            {active !== "user" && <h2>Welcome Back Admin! </h2>}
            {active === "user" ? (
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="form-group">
                  <label htmlFor="email">User Email</label>
                  <input
                    type="email"
                    placeholder="Enter your email"
                    defaultValue={email}
                    {...register("email")}
                  />
                  {userErrors.email && (
                    <span className="errorMsg text-danger text-sm">
                      {userErrors.email?.message}
                    </span>
                  )}
                </div>
                <div className="form-group">
                  <label htmlFor="password">Password</label>
                  <input
                    type="password"
                    placeholder="Enter your password"
                    defaultValue={password}
                    {...register("password")}
                  />
                  {userErrors.password && (
                    <span className="errorMsg text-danger text-sm">
                      {userErrors.password?.message}
                    </span>
                  )}
                </div>
                {logging ? <p>Sending...</p> : error ? <p>{error}</p> : null}
                <button type="submit" className="login-btn" disabled={logging}>
                  Login
                </button>
              </form>
            ) : (
              <>{""}</>
            )}
            <div className="login-options">
              <Link to="/login/forgot">Forgot password?</Link>
              <span>
                Don&#39;t have an account? <Link to="/signup">Sign up</Link>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
