import React, { useState, useEffect, useRef } from "react";
import { FaPlus } from "react-icons/fa";
import "react-quill/dist/quill.snow.css";
import QuillEditor from "../QuillEditor";
import axios from "../../helpers/axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { MdEdit } from "react-icons/md";
import { IoMdAdd } from "react-icons/io";
import { IoMdRemove } from "react-icons/io";
import { getFileNameFromUrl } from "../../utils";

export const ChapterContentModal = ({ chapterData, editChapter }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [chapters, setChapters] = useState([]);
  const [title, setTitle] = useState(chapterData.title || "");
  const [removedIds, setRemovedIds] = useState([]);
  const [filename, setFilename] = useState("");

  useEffect(() => {
    if (chapterData) {
      setChapters(
        chapterData.lectures.map((lecture) => {
          return {
            id: lecture.id,
            lectTitle: lecture.title,
            video_url: lecture.video_url,
            pdf_url: lecture.pdf_url,
            video_file: lecture.video_file,
          };
        })
      );
    }
  }, [chapterData.id]);
  // console.log("chapterdata: ", chapterData);
  const handleAddTag = () => {
    setChapters([
      ...chapters,
      { lectTitle: "", video_url: "", pdf_url: null, video_file: null },
    ]);
  };

  const handleChangeOptional = (index, field, value) => {
    const newChapters = [...chapters];
    newChapters[index][field] = value;
    setChapters(newChapters);
  };

  const handleFileChange = (index, field, file) => {
    const { files } = file;
    const newChapters = [...chapters];
    newChapters[index][field] = files[0];
    setChapters(newChapters);
    setFilename(files[0].name);
  };

  const handleVideoChange = (index, field, file) => {
    const { files } = file;
    const newChapters = [...chapters];
    newChapters[index][field] = files[0];
    setChapters(newChapters);
    // setFilename(files[0].name);
  };

  const handleRemoveTag = (index) => {
    // setChapters(chapters.filter((_, i) => i !== index));
    const newChapters = [...chapters];
    const removedOption = newChapters.splice(index, 1)[0];
    if (removedOption.id) {
      setRemovedIds([...removedIds, removedOption.id]);
    }
    setChapters(newChapters);
  };

  const isFile = (input) => {
    return input instanceof File;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (title) {
      const formData = new FormData();
      formData.append("title", title);

      //  edit chapter title
      try {
        await axios
          .put(`/chapters/${chapterData.id}/`, formData)
          .then((res) => {
            if (res.data) {
              // console.log("res", res);
              setLoading(false);
              toast.success("Chapter Title Updated Successfully!");
              setIsOpen(false);
              editChapter(res.data);
            } else {
              setLoading(false);
              toast.error("Failed to update question");
            }
          })
          .catch((error) => {
            setLoading(false);
            toast.error("Failed to update question");
          });
      } catch (error) {
        setLoading(false);
        console.log("Error occurred while update question:", error);
      }
    }
    // console.log("removedIds", removedIds);
    if (chapters.length) {
      const promises = chapters.map(async (chapter, index) => {
        let formData;

        if (isFile(chapter.pdf_url)) {
          formData = new FormData();
          formData.append("title", chapter.lectTitle);
          formData.append("video_url", chapter.video_url);
          formData.append("pdf_url", chapter.pdf_url);
          formData.append("video_file", chapter.video_file);
        } else {
          formData = {
            title: chapter.lectTitle,
            video_url: chapter.video_url,
            pdf_url: chapter.pdf_url,
            video_file: chapter.video_file,
          };
        }

        try {
          let res;
          // if (chapter.id) {
          // res = await axios.put(`/lectures/${chapter.id}/`, formData, {
          //   headers: isFile(chapter.pdf_url)
          //     ? { "Content-Type": "multipart/form-data" }
          //     : { "Content-Type": "application/json" },
          // });
          // if (!res) {
          //   toast.error("Failed to update lecture");
          // }
          // } else {
          // }
          if (!chapter.id) {
            res = await axios.post(
              `/chapters/${chapterData.id}/lectures/create/`,
              formData
            );
          }
          // if (!res) {
          //   toast.error("Failed to create lectures");
          // }
        } catch (error) {
          setLoading(false);
          console.log("Error occurred while creating lecture:", error);
        }
      });

      const deletePromises = removedIds.map(async (id) => {
        try {
          const res = await axios.delete(`/lectures/${id}/`, {
            headers: {
              "Content-Type": "application/json",
            },
          });

          if (!res?.status === 204) {
            toast.error("Failed to delete lecture ");
          }
        } catch (error) {
          console.log("Error occurred while deleting lecture:", error);
          toast.error("Failed to delete lecture");
        }
      });

      try {
        setLoading(false);
        await Promise.all([...promises, ...deletePromises]);
        toast.success("All lectures updates successfully!");
        setIsOpen(false);
      } catch (error) {
        toast.error("An error occurred while updating/deleting lectures.");
      } finally {
        setLoading(false);
      }
    }
  };

  // console.log("chapter: ", chapter);
  return (
    <div className="">
      <button
        className="text-blue-500 flex items-center bg-blue-500 text-white px-2 py-2 rounded"
        onClick={() => setIsOpen(true)}
      >
        <MdEdit size={20} />
        <p>Add chapter lectures</p>
      </button>

      {isOpen && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-md shadow-md w-[90%] md:w-[50%] max-h-[95vh] overflow-scroll">
            <div className="flex justify-between items-center">
              <h2 className="text-xl font-semibold">Edit Chapter</h2>
              <button
                className="text-gray-600 hover:text-gray-900"
                onClick={() => setIsOpen(false)}
              >
                <svg
                  className="h-6 w-6"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
            <form onSubmit={handleSubmit} className="space-y-6">
              <div className=" ">
                <div>
                  <h6 class="mb-2 font-semibold tex-lg capitalize">
                    {" "}
                    Chapter Title *
                  </h6>
                  <input
                    type="text"
                    id="title"
                    placeholder="Enter title"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    required
                    className="input input-bordered w-full"
                  />
                </div>

                <p className="my-2 mx-2 font-semibold border-b text-center py-2">
                  Chapter lectures
                </p>
                {chapters.map((chapter, index) => (
                  <div className=" relative border-b py-3" key={index}>
                    <div className="mt-2">
                      <h6 class="mb-2 font-semibold tex-lg capitalize">
                        {" "}
                        Lecture Title*
                      </h6>
                      <input
                        type="text"
                        id={`title-${index}`}
                        placeholder="Enter lecture title"
                        value={chapter.lectTitle}
                        onChange={(e) =>
                          handleChangeOptional(
                            index,
                            "lectTitle",
                            e.target.value
                          )
                        }
                        required
                        className="input input-bordered w-full"
                      />
                    </div>
                    <div className="mt-2">
                      <h6 class="mb-2 font-semibold tex-lg capitalize">
                        {" "}
                        Lecture Video link*
                      </h6>
                      <input
                        type="url"
                        id={`link-${index}`}
                        placeholder="Enter Video Url"
                        value={chapter.video_url}
                        onChange={(e) =>
                          handleChangeOptional(
                            index,
                            "video_url",
                            e.target.value
                          )
                        }
                        required
                        className="input input-bordered w-full"
                      />
                    </div>
                    <div className="mt-2">
                      <h6 class="mb-2 font-semibold tex-lg capitalize">
                        {" "}
                        Lecture Pdf*
                      </h6>
                      <input
                        type="file"
                        id={`pdf-${index}`}
                        onChange={(e) =>
                          handleFileChange(index, "pdf_url", e.target)
                        }
                        placeholder="Enter pdf"
                        className="file-input file-input-bordered w-full"
                        accept=".pdf"
                      />
                      <p className="font-semibold text-blue-600 my-2">
                        {chapter.id ? (
                          <div>{getFileNameFromUrl(chapter.pdf_url)}</div>
                        ) : (
                          <div>{filename}</div>
                        )}
                      </p>
                    </div>

                    <div className="mt-2">
                      <h6 class="mb-2 font-semibold tex-lg capitalize">
                        {" "}
                        Lecture Video*
                      </h6>
                      <input
                        type="file"
                        id={`video-${index}`}
                        accept="video/*"
                        onChange={(e) =>
                          handleVideoChange(index, "video_file", e.target)
                        }
                        placeholder="Enter video file"
                        className="file-input file-input-bordered w-full"
                      />
                    </div>

                    <button
                      type="button"
                      className="cursor-pointer font-bold text-xl absolute top-[0px] right-[10px]"
                      onClick={() => handleRemoveTag(index)}
                    >
                      &times;
                    </button>
                  </div>
                ))}
                <button
                  type="button"
                  className="bg-gray-400 hover:bg-gray-300 rounded py-1 px-2  flex items-center text-sm mt-2 transaction duration-400"
                  onClick={() => handleAddTag()}
                >
                  <div className="mr-1">
                    <IoMdAdd />
                  </div>
                  <div>Add More</div>
                </button>
              </div>

              <div className="mt-6 flex justify-end">
                <button
                  className=" px-4 py-2 bg-gray-600 text-white rounded-md"
                  onClick={() => setIsOpen(false)}
                >
                  Close
                </button>
                <button
                  type="submit"
                  className="flex items-center ml-2 px-4 py-2 bg-blue-600 text-white rounded-md"
                  disabled={loading}
                >
                  {loading && (
                    <span className="loading loading-spinner loading-sm mr-2"></span>
                  )}{" "}
                  Save Changes
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};
