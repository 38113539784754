import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "../../../helpers/axios";

const Video = () => {
  const { id } = useParams();
  const [video, setVideo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    const fetchVideo = async () => {
      try {
        const response = await axios.get(`/nsangiza/video/${id}/`);
        setVideo(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching video:", error);
        setLoading(false);
      }
    };
    fetchVideo();
  }, [id]);

  useEffect(() => {
    axios
      .get("/add-categories/")
      .then((res) => {
        setCategories(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  if (loading) {
    return (
      <div className="flex justify-center my-5">
        <span class="loading loading-bars loading-lg"></span>
      </div>
    );
  }

  if (!video) {
    return (
      <div className="flex justify-center items-center h-screen bg-gray-100">
        <div className="bg-white p-8 rounded-lg shadow-md">
          <h2 className="text-2xl font-bold text-red-600 mb-4 text-center">
            Video Not Found
          </h2>
          <p className="text-gray-600 text-center">
            Sorry, we couldn't find the video you're looking for.
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className="w-[93%] mx-auto my-4">
      <h1 className="text-2xl font-bold mb-4">{video.title}</h1>
      <div className="flex flex-col md:flex-row gap-4">
        <div className="w-full md:w-2/3">
          <iframe
            className="w-full aspect-video"
            src={`https://www.youtube.com/embed/${video.youtube_link.split('/').pop()}`}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
          <div className="mt-4">
            <p className="text-sm text-gray-500">
              {new Date(video.date).toLocaleDateString()}
              • {video.views} views
            </p>
            <p
              className="mt-2"
              dangerouslySetInnerHTML={{
                __html: video.description,
              }}
            />
          </div>
        </div>
        <div className="w-full md:w-1/3">
          <div className="bg-gray-100 p-4 rounded-lg">
            <h2 className="text-lg font-semibold mb-2">Video Information</h2>
            <p>
              <strong>Author:</strong> {video.author.first_name}{" "}
              {video.author.last_name}
            </p>
            {video.categories[0] && (
              <p className=" ">
                <strong>Category</strong> :{" "}
                <span className="">
                  {
                    categories.find((cat) => cat.id === video.categories[0])
                      ?.name
                  }
                </span>
              </p>
            )}
            {/* <p>
              <strong>Likes:</strong> {video.likes}
            </p> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Video;
