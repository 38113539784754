import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { GiHamburgerMenu } from "react-icons/gi";
import BwengeLogo from "./../imgs/Logowhite.jpg";
import useWindowDimensions from "./useWindowDimensions";
import "./styles/header.css";
import AvatarSide from "./AvatarSide";

const Header = ({ auth, setAuth }) => {
  const [loginModal, setLoginModal] = useState(false);
  const { height, width } = useWindowDimensions();
  const [toggleMenu, setToggleMenu] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const userInfo = JSON.parse(localStorage.getItem("userInfo")) || {};
  const { fullName = "", profileImage = "" } = userInfo;
  const firstLetter = fullName ? fullName.charAt(0).toUpperCase() : "";
  return (
    <nav className="w-full main-nav bg-[#E0F8FF]">
      <div className="w-[93%]  mx-auto flex justify-between items-center">
        <div className="flex items-center">
          <Link className="flex items-center block" to="/">
            <img src={BwengeLogo} alt="Bwenge Logo" className="w-28 h-28 " />
          </Link>
        </div>
        <div className="hidden md:flex space-x-4  items-start ">
          <ul className="flex mt-2 ">
            <li className="no-underline mx-2">
              <Link
                to="/"
                className="text-xl text-[#280f91] font-medium block no-underline"
              >
                HOME
              </Link>
            </li>
            <li className="mx-2">
              <Link
                to="/about"
                className="text-xl  font-medium block  no-underline text-[#280f91]"
              >
                ABOUT
              </Link>
            </li>
            <li className="mx-2">
              <Link
                to="/projects"
                className="text-xl font-medium block  no-underline text-[#280f91]"
              >
                PROJECTS
              </Link>
            </li>
            <li className="mx-2">
              <Link
                to="/COMMUNITIES"
                className="text-xl font-medium block  no-underline text-[#280f91]"
              >
                COMMUNITIES
              </Link>
            </li>
            <li className="mx-2">
              <Link
                to="/courses"
                className="text-xl font-medium block  no-underline text-[#280f91]"
              >
                COURSES
              </Link>
            </li>
            <li className="mx-2">
              <Link
                to="/nsangiza"
                className="text-xl font-medium block  no-underline text-[#280f91]"
              >
                NSANGIZA
              </Link>
            </li>
            {localStorage.getItem("institution") && (
              <li className="mx-2">
                <Link
                  to="/institutiondashboard"
                  className="text-xl font-medium block  no-underline text-[#280f91]"
                >
                  My institution
                </Link>
              </li>
            )}
          </ul>
        </div>
        <div className="hidden md:flex items-center">
          <AvatarSide auth={auth} setAuth={setAuth} />
        </div>
        <div className="md:hidden">
          <button
            onClick={() => setIsOpen(!isOpen)}
            className="text-black focus:outline-none"
          >
            <svg
              className="h-6 w-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d={isOpen ? "M6 18L18 6M6 6l12 12" : "M4 6h16M4 12h16m-7 6h7"}
              />
            </svg>
          </button>
        </div>
      </div>
      {isOpen && (
        <div className="md:hidden">
          <ul className="flex flex-col sm:flex-row">
            <li className="no-underline py-2 border-b">
              <Link
                to="/"
                className="text-md font-semibold block  no-underline text-[#280f91]"
              >
                HOME
              </Link>
            </li>
            <li className="no-underline py-2 border-b">
              <Link
                to="/about"
                className="text-md font-semibold block  no-underline text-[#280f91]"
              >
                ABOUT
              </Link>
            </li>
            <li className="no-underline py-2 border-b">
              <Link
                to="/projects"
                className="text-md font-semibold block  no-underline text-[#280f91]"
              >
                PROJECTS
              </Link>
            </li>
            <li className="no-underline py-2 border-b">
              <Link
                to="/COMMUNITIES"
                className="text-md font-semibold block  no-underline text-[#280f91]"
              >
                COMMUNITIES
              </Link>
            </li>
            <li className="no-underline py-2 border-b">
              <Link
                to="/courses"
                className="text-md font-semibold block  no-underline text-[#280f91]"
              >
                COURSES
              </Link>
            </li>
            <li className="no-underline py-2 border-b">
              <Link
                to="/nsangiza"
                className="text-md font-semibold block  no-underline text-[#280f91]"
              >
                NSANGIZA
              </Link>
            </li>
            {localStorage.getItem("institution") && (
              <li className="no-underline py-2">
                <Link
                  to="/institutiondashboard"
                  className="text-md font-semibold block  no-underline text-[#280f91]"
                >
                  My institution
                </Link>
              </li>
            )}
          </ul>

          <div className="my-2">
            <AvatarSide />
          </div>
        </div>
      )}
    </nav>
  );
};

export default Header;
