import React, { useState, useEffect, useRef } from "react";
import { FaPlus } from "react-icons/fa";
import "react-quill/dist/quill.snow.css";
import QuillEditor from "../QuillEditor";
import axios from "../../helpers/axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const CreateVideo = ({ addVideo }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [link, setLink] = useState("");
  const [posterImage, setPosterImage] = useState(null);
  const [videoFile, setVideoFile] = useState(null);
  const [userInfo, setUserInfo] = useState({});
  const [categories, setCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);

  useEffect(() => {
    const savedUserInfo = localStorage.getItem("userInfo");
    if (savedUserInfo) {
      setUserInfo(JSON.parse(savedUserInfo));
    }
  }, []);

  useEffect(() => {
    axios
      .get("/add-categories/")
      .then((res) => {
        setCategories(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const handleQuillChange = (value) => {
    setDescription(value);
  };
  const today = new Date().toISOString().split("T")[0];

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!link.includes("youtube") && !link.includes("youtu.be")) {
      toast.error("Please enter a valid YouTube link");
      return;
    }

    setLoading(true);

    const formData = new FormData();
    formData.append("title", title);
    formData.append("description", description);
    formData.append("poster_image", posterImage);
    formData.append("youtube_link", link);
    formData.append("user_id", userInfo.userId);
    formData.append("categories", selectedCategories);

    await axios
      .post("/nsangiza/video/create", formData)
      .then((res) => {
        // console.log("res", res);
        setLoading(false);
        if (res.data) {
          toast.success("Video created successfully!");
          setIsOpen(false);
          setTitle("");
          setDescription("");
          setPosterImage(null);
          setLink("");
          setSelectedCategories([]);
          addVideo(res.data);
        } else {
          setLoading(false);
          toast.error("Failed to create video");
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error("Failed to create video");
      });
  };

  return (
    <div className="">
      <button
        className="px-4 py-2 bg-[#198754] text-white rounded-md flex items-center"
        onClick={() => setIsOpen(true)}
      >
        <div>
          <FaPlus />
        </div>
        <div className="ml-2">Create Video</div>
      </button>

      {isOpen && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-md shadow-md w-[90%] md:w-[50%] max-h-[95vh] overflow-y-auto ">
            <div className="flex justify-between items-center">
              <h2 className="text-2xl font-semibold">Create Video</h2>
              <button
                className="text-gray-600 hover:text-gray-900"
                onClick={() => setIsOpen(false)}
              >
                <svg
                  className="h-6 w-6"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
            <form onSubmit={handleSubmit} className="space-y-6">
              <div>
                <label
                  htmlFor="title"
                  className="block text-sm font-medium text-gray-700 mb-2"
                >
                  Title
                </label>
                <input
                  type="text"
                  id="title"
                  placeholder="Enter title"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  required
                  className="input input-bordered w-full"
                />
              </div>
              <div className=" ">
                <label
                  htmlFor="description"
                  className="block text-sm font-medium text-gray-700 mb-2"
                >
                  Description
                </label>
                <QuillEditor value={description} onChange={handleQuillChange} />
              </div>

              <div>
                <label
                  htmlFor="posterImage"
                  className="block text-sm font-medium text-gray-700 mb-2"
                >
                  Poster Image
                </label>
                <div className="mt-1">
                  <input
                    type="file"
                    id="posterImage"
                    name="posterImage"
                    accept="image/*"
                    className="input input-bordered w-full"
                    onChange={(e) => setPosterImage(e.target.files[0])}
                  />
                </div>
              </div>

              <div>
                <label
                  htmlFor="url_content"
                  className="block text-sm font-medium text-gray-700"
                >
                  Youtube Link
                </label>
                <div className="mt-1 flex rounded-md shadow-sm">
                  <span className="inline-flex items-center rounded-l-md border border-r-0 border-gray-300 bg-gray-50 px-3 text-sm text-gray-500">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-5 h-5"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244"
                      />
                    </svg>
                  </span>
                  <input
                    type="url"
                    id="url_content"
                    name="url_content"
                    maxLength="200"
                    className="input input-bordered w-full"
                    placeholder="https://example.com"
                    value={link}
                    onChange={(e) => setLink(e.target.value)}
                  />
                </div>
              </div>

              <div>
                <label
                  htmlFor="categories"
                  className="block text-sm font-medium text-gray-700 mb-2"
                >
                  Categories
                </label>
                <div className="mt-1">
                  <select
                    id="categories"
                    name="categories"
                    className="select select-bordered w-full"
                    multiple
                    onChange={(e) =>
                      setSelectedCategories(
                        Array.from(e.target.selectedOptions, (option) =>
                          parseInt(option.value, 10)
                        )
                      )
                    }
                  >
                    {categories.map((category) => (
                      <option key={category.id} value={category.id}>
                        {category.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="mt-6 flex justify-end">
                <button
                  className=" px-4 py-2 bg-gray-600 text-white rounded-md"
                  onClick={() => setIsOpen(false)}
                >
                  Close
                </button>
                <button
                  type="submit"
                  className="flex items-center ml-2 px-4 py-2 bg-blue-600 text-white rounded-md"
                  disabled={loading}
                >
                  {loading && (
                    <span className="loading loading-spinner loading-sm mr-2"></span>
                  )}{" "}
                  Create Video
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default CreateVideo;
