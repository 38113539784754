import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
// import { LoginModal } from "../../components/LoginModal";
import { useNavigate } from "react-router-dom";
import DOMPurify from "dompurify";
import axios from "../../../helpers/axios";
import { FaDownload } from "react-icons/fa";
import { LoginModal } from "../../LoginModal";
import { saveAs } from "file-saver";

export const SingleProject = ({ auth }) => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [project, setProject] = useState([]);
  const [isLogin, setIsLogin] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  const userInfo = JSON.parse(localStorage.getItem("userInfo")) || {};
  const [isDownloading, setIsDownloading] = useState(false);

  useEffect(() => {
    setLoading(true);
    axios
      .get(`/projects/${id}/`)
      .then((res) => {
        if (res.data) {
          setLoading(false);
          setProject(res.data);
        } else {
          setLoading(false);
          console.log("error happen");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        setLoading(false);
      });
  }, [id]);

  useEffect(() => {
    if (auth?.userEmail || userInfo?.userEmail) {
      setIsLogin(true);
    }
  }, [auth, userInfo]);

  const handleDownloadClick = async () => {
    if (!isLogin) {
      setShowModal(true);
      return; // Prevent further execution if not logged in
    }

    setIsDownloading(true);

    const handleDownload = () => {
      axios.get(`/projects/${project.id}/download/`, {
        responseType: "blob",
      })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          // console.log(url);
          var blob = new Blob([response.data], {
            type: "text/plain;charset=utf-8",
          });
          saveAs(blob, `${project.topics}.pdf`);
          setIsDownloading(false);
        })
        .catch((err) => {
          console.log(err);
          setIsDownloading(false);
        })
    };
    handleDownload();
  };

  return (
    <div className="container my-10">
      <div>
        <div>
          {loading ? (
            <div>
              <div class="flex justify-center w-[100%]">
                <span class="loading loading-bars loading-lg"></span>
              </div>
            </div>
          ) : (
            <div>
              <div>
                <div className="flex justify-between">
                  <h2 className="mb-3">{project.topics}</h2>
                  <div>
                    <button
                      onClick={() => handleDownloadClick(project)}
                      className="btn btn-primary text-lg font-medium"
                      disabled={isDownloading}
                    >
                      <div className="flex items-center text-white block">
                        {isDownloading ? (
                          <span className="loading loading-spinner loading-sm"></span>
                        ) : (
                          <FaDownload />
                        )}
                        <div className="ml-2 text-white">
                          {isDownloading ? "Downloading..." : "Download"}
                        </div>
                      </div>
                    </button>
                    {!isLogin && showModal && (
                      <LoginModal
                        setIsLogin={setIsLogin}
                        onClose={() => setShowModal(false)}
                        content={"Please login to download the file"}
                      />
                    )}
                  </div>
                </div>
                <div className="mt-3">
                  <p className="text-lg">{project.description}</p>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
