import React, { useState, useEffect, useRef } from "react";
import { FaPlus } from "react-icons/fa";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import QuillEditor from "../QuillEditor";
import axios from "../../helpers/axios";
import { useForm } from "react-hook-form";
import { createArticleSchema } from "../validations/articleSchema";
import { yupResolver } from "@hookform/resolvers/yup";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const CreateProjectModal = ({ addProject }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [topic, setTopic] = useState("");
  const [description, setDescription] = useState("");
  const [tags, setTags] = useState("");
  const [level, setLevel] = useState("");
  const [projectFile, setProjectFile] = useState(null);
  const [institution, setInstitution] = useState("");
  const [institutions, setInstitutions] = useState("");
  const [institutionLoad, setInstitutionLoad] = useState(true);
  const [userInfo, setUserInfo] = useState({});

  useEffect(() => {
    const savedUserInfo = localStorage.getItem("userInfo");
    if (savedUserInfo) {
      setUserInfo(JSON.parse(savedUserInfo));
    }
  }, []);

  useEffect(() => {
    axios
      .get("/institutions/")
      .then((res) => {
        setInstitutionLoad(false);
        // console.log('insitition', res)
        if (res) {
          setInstitutions(res.data);
        }
      })
      .catch((error) => {
        setInstitutionLoad(false);
        // console.error("Error:", error);
      });
  }, []);

  const handleFileChange = (e) => {
    setProjectFile(e.target.files[0]);
  };

  const handleCreateProjects = async (e) => {
    e.preventDefault();
    setLoading(true);
    // console.log("userid: ", userInfo?.userId);
    const formData = new FormData();
    formData.append("topics", topic);
    formData.append("description", description);
    formData.append("tags", tags);
    formData.append("level", level);
    formData.append("user_id", userInfo.userId);
    formData.append("institution", institution);
    

    if (projectFile) {
      formData.append("file", projectFile, projectFile.name);
    }

    try {
      await axios
        .post("/projects/create/", formData)
        .then((res) => {
          if (res.data) {
            setLoading(false);
            addProject(res?.data);
            setIsOpen(false);
            toast.success("Project created successfully");
            // Reset form states
            setTopic("");
            setDescription("");
            setTags("");
            setLevel("");
            setProjectFile(null);
            setInstitution("");
          }
        })
        .catch((error) => {
          // console.log("error", error);
          setLoading(false);
          toast.error("Failed to create project");
        });
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <div className="">
      <button
        className="px-4 py-2 bg-[#198754] text-white rounded-md flex items-center"
        onClick={() => setIsOpen(true)}
      >
        <div>
          <FaPlus />
        </div>
        <div className="ml-2">Create Project</div>
      </button>

      {isOpen && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-md shadow-md w-[90%] md:w-[50%] max-h-[95vh] overflow-y-auto ">
            <div className="flex justify-between items-center">
              <h2 className="text-2xl font-semibold">Create Project</h2>
              <button
                className="text-gray-600 hover:text-gray-900"
                onClick={() => setIsOpen(false)}
              >
                <svg
                  className="h-6 w-6"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
            <div className="mt-4">
              <form onSubmit={handleCreateProjects} className="space-y-6">
                <div>
                  <label
                    htmlFor="topic"
                    className="block text-sm font-medium text-gray-700 mb-2"
                  >
                    Topic
                  </label>
                  <input
                    type="text"
                    id="topic"
                    placeholder="Enter topic"
                    value={topic}
                    onChange={(e) => setTopic(e.target.value)}
                    required
                    className="input input-bordered w-full"
                  />
                </div>
                <div>
                  <label
                    htmlFor="description"
                    className="block text-sm font-medium text-gray-700 mb-2"
                  >
                    Description
                  </label>
                  <textarea
                    id="description"
                    rows={3}
                    placeholder="Enter description"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    required
                    className="border p-3 rounded w-full"
                  />
                </div>
                <div>
                  <label
                    htmlFor="projectFile"
                    className="block text-sm font-medium text-gray-700 mb-2"
                  > 
                    File (PDF only)
                  </label>
                  <input
                    type="file"
                    id="projectFile"
                    name="projectFile"
                    onChange={handleFileChange}
                    accept=".pdf"
                    className="file-input file-input-bordered w-full"
                  />
                </div>
                <div>
                  <label
                    htmlFor="tags"
                    className="block text-sm font-medium text-gray-700 mb-2"
                  >
                    Tags (separate with commas)
                  </label>
                  <input
                    type="text"
                    id="tags"
                    placeholder="Enter tags, separated by commas"
                    value={tags}
                    onChange={(e) => setTags(e.target.value)}
                    className="input input-bordered w-full"
                  />
                </div>
                <div>
                  <label
                    htmlFor="level"
                    className="block text-sm font-medium text-gray-700 mb-2"
                  >
                    Level
                  </label>
                  <select
                    id="level"
                    value={level}
                    onChange={(e) => setLevel(e.target.value)}
                    required
                    className="select select-bordered w-full"
                  >
                    <option value={""} disabled>
                      Select Institution
                    </option>
                    <option value="Bachelor">Bachelor</option>
                    <option value="Masters"> Masters</option>
                    <option value="PhD">PhD</option>
                    {/* Add more options for institutions if needed */}
                  </select>
                </div>

                <div>
                  <label
                    htmlFor="institution"
                    className="block text-sm font-medium text-gray-700 mb-2"
                  >
                    Institution
                  </label>
                  <select
                    id="institution"
                    value={institution}
                    onChange={(e) => setInstitution(e.target.value)}
                    required
                    className="select select-bordered w-full"
                  >
                    <option value="">Select Institution</option>
                    {institutions &&
                      Array.isArray(institutions) &&
                      institutions.map((inst, index) => (
                        <option key={index} value={inst.id || inst.name}>
                          {inst.name}
                        </option>
                      ))}
                  </select>
                </div>

                <div className="mt-6 flex justify-end">
                  <button
                    className="px-4 py-2 bg-gray-600 text-white rounded-md"
                    onClick={() => setIsOpen(false)}
                  >
                    Close
                  </button>
                  <button
                    className="flex items-center ml-2 px-4 py-2 bg-blue-600 text-white rounded-md"
                    onClick={handleCreateProjects}
                    disabled={loading}
                  >
                    {loading && (
                      <span class="loading loading-spinner loading-sm mr-2"></span>
                    )}{" "}
                    Add Project
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CreateProjectModal;