import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "../../../helpers/axios";
import { Tab, TabList, Tabs, TabPanel } from "react-tabs";
import DOMPurify from "dompurify";
import userLogo from "../../../imgs/user_log.png";
import CreatePost from "../../Actions/CreatePost";
import { toast, ToastContainer } from "react-toastify";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { GoBack } from "../../GoBack";

const SingleCommunity = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [community, setcommunity] = useState(false);
  const [userInfo, setUserInfo] = useState({});
  const [stars, setStars] = useState([]);
  const [starLoading, setStarLoading] = useState(true);
  let no = 1;
  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  useEffect(() => {
    const savedUserInfo = localStorage.getItem("userInfo");
    if (savedUserInfo) {
      setUserInfo(JSON.parse(savedUserInfo));
    }
  }, [setUserInfo]);

  // redirect back to login
  useEffect(() => {
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    if (!userInfo) {
      navigate("/login");
      return;
    }
  }, []);

  useEffect(() => {
    setLoading(true);
    axios
      .get(`/communities/${id}/`)
      .then((res) => {
        // console.log("community", res.data);

        if (res.data) {
          setLoading(false);
          setcommunity(res.data);
        } else {
          setLoading(false);
          // console.log("error happen");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        setLoading(false);
      });
  }, []);

  const getStars = () => {
    axios
      .get(`/communities/${community.id}/stars/`)
      .then((res) => {
        setStarLoading(false);
        if (res.data) {
          setStarLoading(false);
          setStars(res.data);
        } else {
          // console.log("error happen");
        }
      })
      .catch((error) => {
        setStarLoading(false);
        console.error("Error:", error);
        setLoading(false);
      });
  };
  // get stars
  useEffect(() => {
    getStars();
  }, [community]);
  // console.log('community: ', community);

  const [isMember, setIsMember] = useState(false);

  useEffect(() => {
    if (community && userInfo) {
      const userIsMember = community.members.some(
        (member) => member.id === userInfo.userId
      );
      setIsMember(userIsMember);
      if (!userIsMember) {
        joinCommunity();
      }
    }
  }, [community, userInfo]);

  const joinCommunity = () => {
    const formData = new FormData();
    formData.append("user_id", userInfo.id);
    setLoading(true);
    axios
      .post(`/communities/${community.id}/join/`, formData)
      .then((res) => {
        // console.log("res", res);
        if (res.data) {
          toast.success("Joined Community successfully");
        } else {
          toast.error("Fail to Join community, Try later");
        }
      })
      .catch(() => {
        toast.error("Fail to Join community, Try later");
        // console.log("error");
      });
  };

  // get community posts
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    axios
      .get(`/posts/`)
      .then((res) => {
        if (res.data) {
          const filteredPosts = res.data.filter(
            (post) => post.community === community.id
          );
          // console.log("filteredPosts", filteredPosts);

          setPosts(filteredPosts);
        } else {
          // console.log("error happen");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, [community.id]);

  // update post after create new post
  const addPost = (newPost) => {
    setPosts((prevPosts) => [newPost, ...prevPosts]);
  };
  // console.log("posts", posts);
  return (
    <div className="container my-10">
      <GoBack />
      <ToastContainer />
      <div>
        {loading ? (
          <div className="flex justify-center w-full">
            <span className="loading loading-bars loading-lg"></span>
          </div>
        ) : (
          <div className="space-y-6 mt-3">
            <div className="bg-white rounded-lg shadow-md overflow-hidden">
              <img
                src={community.poster_image}
                alt={community.name}
                className="w-full h-64 object-cover"
              />
              <h2 className="text-2xl font-bold p-4">{community.name}</h2>
            </div>
            <div className="bg-gray-100 rounded-lg shadow-md border">
              <Tabs>
                <TabList className="flex border-b">
                  <Tab className="px-4 py-3 font-semibold hover:bg-gray-100 cursor-pointer">
                    Posts
                  </Tab>
                  <Tab className="px-4 py-3 font-semibold hover:bg-gray-100 cursor-pointer">
                    About
                  </Tab>
                  <Tab className="px-4 py-3 font-semibold hover:bg-gray-100 cursor-pointer">
                    Members ({community.members ? community.members.length : 0})
                  </Tab>
                  <Tab className="px-4 py-3 font-semibold hover:bg-gray-100 cursor-pointer relative">
                  
                    Stars
                    {stars.length > 0 && (
                      <span className="absolute right-2 w-2 h-2 bg-blue-500 rounded-full"></span>
                    )}
                  </Tab>
                </TabList>
                <TabPanel>
                  <div className="p-4 max-w-3xl mx-auto">
                    <div className="flex justify-between items-center mb-6">
                      <h4 className="text-xl font-semibold">Community posts</h4>
                      <CreatePost
                        community={community}
                        addPost={addPost}
                        getStars={getStars}
                      />
                    </div>
                    <div className="space-y-6">
                      {loading ? (
                        <div className="flex justify-center">
                          <span className="loading loading-bars loading-lg"></span>
                        </div>
                      ) : posts.length > 0 ? (
                        posts.map((post) => (
                          <div
                            key={post.id}
                            className="bg-white rounded-lg shadow-md overflow-hidden"
                          >
                            <div className="border-b p-4 flex items-center justify-between">
                              <div className="flex items-center">
                                <img
                                  src={userLogo}
                                  alt=""
                                  className="w-10 h-10 rounded-full mr-3"
                                />
                                <div className="text-black font-medium">
                                  {post.author?.first_name || ""}
                                </div>
                              </div>
                              <div className="text-sm text-gray-500">
                                {new Date(post.created_at).toLocaleDateString()}
                              </div>
                            </div>
                            <div className="px-4 pt-3">
                              {post.title && (
                                <h3 className="text-xl font-semibold mb-2">
                                  {post.title}
                                </h3>
                              )}
                            </div>
                            <div className="p-4">
                              {post.content_type === "text" &&
                                post.text_content && (
                                  <div className="space-y-2">
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: DOMPurify.sanitize(
                                          post.text_content
                                        ),
                                      }}
                                    />
                                  </div>
                                )}
                              {post.content_type === "file" &&
                                post.file_content && (
                                  <div className="mb-4">
                                    <a
                                      href={post.file_content}
                                      download
                                      className="no-underline flex items-center space-x-2 text-blue-500 hover:text-blue-600 bg-blue-50 hover:bg-blue-100 px-4 py-2 rounded-full transition duration-300 ease-in-out"
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="h-5 w-5"
                                        viewBox="0 0 20 20"
                                        fill="currentColor"
                                      >
                                        <path
                                          fillRule="evenodd"
                                          d="M3 17a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm3.293-7.707a1 1 0 011.414 0L9 10.586V3a1 1 0 112 0v7.586l1.293-1.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                                          clipRule="evenodd"
                                        />
                                      </svg>
                                      <span>Download File</span>
                                    </a>
                                  </div>
                                )}
                              {post.content_type === "url" &&
                                post.url_content && (
                                  <div className="space-y-2">
                                    <a
                                      href={post.url_content}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      className="text-blue-500 hover:underline break-all"
                                    >
                                      {post.url_content}
                                    </a>
                                  </div>
                                )}
                              {post.content_type === "image" &&
                                post.image_content && (
                                  <div className="mb-4">
                                    <img
                                      src={post.image_content}
                                      alt="Post Image"
                                      className="w-full h-80 object-cover rounded-lg"
                                    />
                                  </div>
                                )}
                              {post.content_type === "video" &&
                                post.video_content && (
                                  <div className="mb-4">
                                    <div className="relative">
                                      <video
                                        src={post.video_content}
                                        className="w-full h-auto max-h-96 object-contain rounded-lg"
                                        autoPlay
                                        muted
                                        loop
                                      />
                                      <button
                                        className="absolute bottom-4 right-4 bg-black bg-opacity-50 text-white p-2 rounded-full"
                                        onClick={(e) => {
                                          const video = e.target
                                            .closest(".relative")
                                            .querySelector("video");
                                          video.muted = !video.muted;
                                          e.target.innerHTML = video.muted
                                            ? "🔇"
                                            : "🔊";
                                        }}
                                      >
                                        🔇
                                      </button>
                                    </div>
                                  </div>
                                )}
                            </div>
                          </div>
                        ))
                      ) : (
                        <div className="text-center text-gray-500">
                          No posts available.
                        </div>
                      )}
                    </div>
                  </div>
                </TabPanel>
                <TabPanel>
                  <div className="p-4">
                    <h4 className="text-xl font-semibold mb-4">
                      About Community
                    </h4>
                    <p className="text-lg">{community.description}</p>
                  </div>
                </TabPanel>
                <TabPanel>
                  <div className="p-4">
                    <h4 className="text-xl font-semibold mb-4">
                      Community Members
                    </h4>
                    <div className="space-y-4">
                      {community?.members?.length > 0 ? (
                        community.members.map((member, index) => (
                          <div key={index} className="flex items-center">
                            <img
                              src={userLogo}
                              alt=""
                              className="w-10 h-10 rounded-full mr-3"
                            />
                            <div>
                              <div className="font-medium">
                                {member.first_name}
                              </div>
                              <div className="text-sm text-gray-500">
                                {member.email}
                              </div>
                            </div>
                          </div>
                        ))
                      ) : (
                        <div>No members in this community.</div>
                      )}
                    </div>
                  </div>
                </TabPanel>
                <TabPanel>
                  <div className="p-4">
                    <h4 className="text-xl font-semibold mb-4">
                      Community Stars
                    </h4>
                    <div>
                      {starLoading ? (
                        <p className="text-lg">Loading stars...</p>
                      ) : stars && stars.length > 0 ? (
                        <table className="w-full mt-3">
                          <thead>
                            <tr className="bg-gray-100">
                              <th className="py-3 px-4 text-left">No.</th>
                              <th className="py-3 px-4 text-left">Member</th>
                              <th className="py-3 px-4 text-left">
                                Total Post
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {stars.map((star, index) => (
                              <tr
                                key={index}
                                className={
                                  index % 2 === 0 ? "bg-white" : "bg-gray-50"
                                }
                              >
                                <td className="py-3 px-4">
                                  {index === 0 ? (
                                    <div className="w-8 h-8 rounded-full bg-primary flex items-center justify-center text-white">
                                      {no++}
                                    </div>
                                  ) : (
                                    no++
                                  )}
                                </td>
                                <td className="py-3 px-4">
                                  <div className="flex items-center">
                                    <img
                                      src={userLogo}
                                      alt=""
                                      className="w-10 h-10 rounded-full mr-3"
                                    />
                                    <span className="font-medium">
                                      {star.member_name}
                                    </span>
                                  </div>
                                </td>
                                <td className="py-3 px-4 font-medium">
                                  {star.post_count}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      ) : (
                        <p>No stars to display.</p>
                      )}
                    </div>
                  </div>
                </TabPanel>
              </Tabs>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SingleCommunity;
