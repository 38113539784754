import React, { useState, useEffect, useRef } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { ToastContainer, toast } from "react-toastify";
import QuillEditor from "../../QuillEditor";
import axios from "../../../helpers/axios";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import userprofile from "../../../imgs/user_log.png";
import { GoBack } from "../../GoBack";

export const EditArticle = () => {
  const { id } = useParams();
  const initialState = {
    title: "",
    description: "",
    teacher: "",
    poster_image: null,
    imagePreview: null,
  };
  const [article, setArticle] = useState(initialState);
  const [loading, setLoading] = useState(true);
  const [editLoading, setEditLoading] = useState(false);

  const navigate = useNavigate();
  // redirect back to login
  useEffect(() => {
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    if (!userInfo?.userEmail) {
      navigate("/login");
      return;
    }
  }, []);

  //   getting article
  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      try {
        const res = await axios.get(`/article/${id}/`);
        console.log("Fetched data:", res.data);
        setArticle({
          ...article,
          title: res.data.title,
          description: res.data.description,
          poster_image: null,
          imagePreview: res.data.poster_image,
        });
      } catch (error) {
        console.error("Error:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);
  console.log("article aft", article);

  const handleQuillChange = (value) => {
    setArticle((prevState) => ({
      ...prevState,
      description: value,
    }));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setArticle({ ...article, [name]: value });
  };
  const handleImageChange = (e) => {
    const { name, files } = e.target;
    const file = files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setArticle({
          ...article,
          imagePreview: reader.result,
          [name]: file,
        });
      };
      reader.readAsDataURL(file);
      console.log("image art: ", file);
    }
    setArticle({ ...article, [name]: files[0] });
  };

  const handleEditArticle = async (e) => {
    setEditLoading(true);
    e.preventDefault();
    console.log("article", article);
    const formData = new FormData();
    formData.append("title", article.title);
    formData.append("description", article.description);
    if (article.poster_image instanceof File) {
      formData.append(
        "poster_image",
        article.poster_image,
        article.poster_image.name
      );
    }
    await axios.patch(`/article/${id}/`, formData).then((res) => {
      if (res?.data) {
        setEditLoading(false);
        console.log("res", res);
        toast.success("Article updated successfully");
      } else {
        setEditLoading(false);
        toast.error("something went wrong while updating Article");
      }
    });
  };

  return (
    <div className="container mx-auto my-5">
      <GoBack />
      {loading ? (
        <div class="flex justify-center w-[100%]">
          <span class="loading loading-bars loading-lg"></span>
        </div>
      ) : (
        <div>
          <h4 className="text-center font-bold">
            Article title: {article.title}
          </h4>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-8 mt-10">
            <div className="border rounded-lg p-5">
              <ToastContainer />
              <h3>Edit Course</h3>
              <form className="space-y-6">
                <div className="mt-4">
                  <div>
                    <label
                      htmlFor="title"
                      className="block text-sm font-medium text-gray-700 my-2"
                    >
                      Title
                    </label>
                    <input
                      type="text"
                      name="title"
                      id="title"
                      className="input input-bordered w-full"
                      defaultValue={article.title}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="my-2">
                    <label
                      htmlFor="description"
                      className="block text-sm font-medium text-gray-700 my-2"
                    >
                      Description
                    </label>
                    <QuillEditor
                      value={article.description}
                      onChange={handleQuillChange}
                    />
                  </div>
                  <div className="my-2">
                    <label
                      htmlFor="poster_image"
                      className="block text-sm font-medium text-gray-700 my-2"
                    >
                      Article Image
                    </label>
                    {article.imagePreview && (
                      <div className="my-2">
                        <img
                          src={article.imagePreview}
                          alt="article image"
                          className="w-[100px] h-[100px] object-cover my-2"
                        />
                      </div>
                    )}
                    <input
                      type="file"
                      name="poster_image"
                      id="poster_image"
                      onChange={handleImageChange}
                      className="file-input file-input-bordered file-input-md w-full"
                    />
                  </div>
                </div>
                <div className="mt-6 flex justify-end">
                  <button
                    type="submit"
                    className="flex items-center ml-2 px-4 py-2 bg-blue-600 text-white rounded-md"
                    disabled={editLoading}
                    onClick={handleEditArticle}
                  >
                    {editLoading && (
                      <span class="loading loading-spinner loading-sm mr-2"></span>
                    )}{" "}
                    Edit Article
                  </button>
                </div>
              </form>
            </div>
            {/* <div className="border rounded-lg p-5">
              <h3 className="text-2xl font-bold">Comments</h3>
              <div className="flex mt-3">
                <div className="w-[10%]">
                  <img
                    src={userprofile}
                    alt="user"
                    className="w-12 h-12 rounded-full"
                  />
                </div>
                <div className="bg-gray-100 p-3 rounded w-[90%]">
                  <div className="text-md font-medium">Mary</div>
                  <p className="text-sm">that was very nice based on my area</p>
                </div>
              </div>
              <div className="flex mt-2">
                <div className="w-[10%]">
                  <img
                    src={userprofile}
                    alt="user"
                    className="w-12 h-12 rounded-full"
                  />
                </div>
                <div className="bg-gray-100 p-3 rounded  w-[90%]">
                  <div className="text-md font-medium">Alice</div>
                  <p className="text-sm">
                    a careful examination or analysis of a phenomenon,
                    development, or question. the published report of such a
                    study.
                  </p>
                </div>
              </div>
              <div className="flex mt-2">
                <div className="w-[10%]">
                  <img
                    src={userprofile}
                    alt="user"
                    className="w-12 h-12 rounded-full"
                  />
                </div>
                <div className="bg-gray-100 p-3 rounded w-[90%]">
                  <div className="text-md font-medium">John Doe</div>
                  <p className="text-sm">that was very nice based on my area</p>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      )}
    </div>
  );
};
