import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import projectbanner from "./../../imgs/project-banner.png";
import { Link } from "react-router-dom";
import { Slides } from "../Slides";
import { ProjectTable } from "../ProjectTable";

const Project = () => {
  const [projects, setProjects] = useState([]);
  const [projectLoading, setProjectLoading] = useState(true);
  const [filteredProjects, setFilteredProjects] = useState([]);

  const [institutions, setInstitutions] = useState([]);
  const [institutionLoading, setInstitutionLoading] = useState(true);
  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await axios.get(
          "https://admin.bwenge.com/api/projects/"
        );
        setProjectLoading(false);
        if (response) {
          // console.log("projects: ", response.data)
          setProjects(response.data);
        }
      } catch (error) {
        setProjectLoading(false);
        console.error("Failed to fetch projects:", error);
      }
    };

    fetchProjects();
  }, []);

  useEffect(() => {
    const fetchInstitution = async () => {
      try {
        const response = await axios.get(
          "https://admin.bwenge.com/api/institutions/"
        );
        setInstitutionLoading(false);
        if (response) {
          // console.log("instut: ", response.data);
          setInstitutions(response.data);
        }
      } catch (error) {
        setProjectLoading(false);
        // console.error("Failed to fetch In:", error);
      }
    };

    fetchInstitution();
  }, []);

  useEffect(() => {
    setFilteredProjects(projects);
  }, [projects]);

  const handleSearch = (searchTerm) => {
    const filtered = projects.filter((project) =>
      project.topics.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredProjects(filtered);
  };

  return (
    <>
      <div className="container mt-5">
        <div className="bg-gray-200 w-full p-5 rounded-lg grid grid-cols-1 md:grid-cols-2">
          <div className="flex items-center">
            <h1 className="font-semibold text-4xl text-center">
              DUSHAKASHAKE DUSHAKIRA U RWANDA
            </h1>
            {/* <h1>Thesis & Dissertation Database</h1>
            <div className="flex flex-col md:flex-row items-center space-x-3 my-3">
              <div>Bachelor’s</div>
              <div className="bg-white border p-2 rounded">
                {" "}
                Doctoral Disertations
              </div>
              <div className="bg-white border p-2 rounded"> Masters Thesis</div>
            </div>
            <p>
              Database Including the Rwandan doctoral dissertations, master’s
              thesis, and bachelor’s projects from all universities and colleges
              in Rwanda. It includes high-quality literature and continuous
              dynamic update.
            </p> */}
          </div>
          <div className="p-5">
            {institutionLoading ? (
              <div className="flex justify-center w-[100%]">
                <span className="loading loading-bars loading-lg"></span>
              </div>
            ) : (
              <div>
                {institutions.length ? (
                  <Slides slidesToShow={3}>
                    {institutions.map((institution, index) => (
                      <Link
                        to={`/institution/${institution.id}/projects`}
                        className="avatar p-2"
                        key={index}
                      >
                        <div className=" rounded-full">
                          <img
                            src={institution.logo}
                            alt={institution.name}
                            className="border border-gray-900 rounded-full"
                          />
                        </div>
                      </Link>
                    ))}
                  </Slides>
                ) : (
                  ""
                )}
              </div>
            )}
          </div>
        </div>

        <div className="mt-5">
          <div className=" flex">
            <div className="bg-gray-200 px-3 font-medium flex items-center">
              <p>Topics</p>
            </div>
            <input
              type="text"
              placeholder="Search projects..."
              className="w-full px-4 py-3 border border-gray-300  focus:outline-none focus:ring-2 focus:ring-blue-500"
              onChange={(e) => handleSearch(e.target.value)}
            />
          </div>
        </div>
        <div className="mt-5">
          <div className="ui raised segment">
            {projectLoading ? (
              <div className="flex justify-center w-[100%]">
                <span className="loading loading-bars loading-lg"></span>
              </div>
            ) : (
              <>
                {filteredProjects.length ? (
                  <div>
                    <ProjectTable projects={filteredProjects} />
                  </div>
                ) : (
                  <div>No projects found</div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Project;
