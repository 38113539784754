import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import { FaAngleDown } from "react-icons/fa6";
import { ProjectTable } from "../../ProjectTable";

export const SingleInstitutionProjects = () => {
  const { id } = useParams();
  const [projects, setProjects] = useState([]);
  const [projectLoading, setProjectLoading] = useState(true);
  const [institution, setInstitution] = useState([]);
  const [institutionLoading, setInstitutionLoading] = useState(true);
  const [filteredProjects, setFilteredProjects] = useState([]);
  const [selectedDegrees, setSelectedDegrees] = useState({
    bachelors: false,
    masters: false,
    phd: false,
  });

  useEffect(() => {
    const fetchInstitution = async () => {
      try {
        const response = await axios.get(
          `https://admin.bwenge.com/api/institutions/${id}/`
        );
        setInstitutionLoading(false);
        if (response) {
          setInstitution(response.data);
        }
      } catch (error) {
        setProjectLoading(false);
      }
    };

    fetchInstitution();
  }, [id]);

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await axios.get(
          "https://admin.bwenge.com/api/projects/"
        );
        setProjectLoading(false);
        console.log(response.data);

        if (response) {
          const filteredProjects = response.data.filter(
            (project) =>
              project.institution && project.institution === parseInt(id)
          );
          setProjects(filteredProjects);
        }
      } catch (error) {
        setProjectLoading(false);
      }
    };

    fetchProjects();
  }, [id]);

  useEffect(() => {
    setFilteredProjects(projects);
  }, [projects]);

  const handleSearch = (searchTerm) => {
    filterProjects(searchTerm);
  };

  const filterProjects = (searchTerm = "") => {
    let filtered = projects.filter((project) =>
      project.topics.toLowerCase().includes(searchTerm.toLowerCase())
    );

    // Apply degree level filtering based on multiple selections
    const { bachelors, masters, phd } = selectedDegrees;

    filtered = filtered.filter((project) => {
      if (bachelors && project.level === "Bachelor") return true;
      if (masters && project.level === "Masters") return true;
      if (phd && project.level === "PhD") return true;
      return false;
    });

    setFilteredProjects(filtered);
  };

  const handleDegreeChange = (degree) => {
    setSelectedDegrees((prevState) => ({
      ...prevState,
      [degree]: !prevState[degree],
    }));
  };

  useEffect(() => {
    filterProjects();
  }, [selectedDegrees]);

  return (
    <div className="container mt-5">
      <div className="mb-4 flex">
        <div className="bg-gray-200 px-3 font-medium flex items-center">
          <p>Topics</p>
        </div>
        <input
          type="text"
          placeholder="Search projects..."
          className="w-full px-4 py-3 border border-gray-300  focus:outline-none focus:ring-2 focus:ring-blue-500"
          onChange={(e) => handleSearch(e.target.value)}
        />
      </div>
      <div className="flex">
        <div className="w-[15%]">
          <div className="avatar">
            <div class="w-32 rounded-full">
              <img src={institution.logo} alt={institution.name} />
            </div>
          </div>

          <div className="mb-3">
            <h3 className="">{institution.name}</h3>
          </div>

          <div className="my-3">
            <div className="text-lg">
              <div className="my-2">
                <input
                  type="checkbox"
                  id="bachelors"
                  className="mr-2"
                  checked={selectedDegrees.bachelors}
                  onChange={() => handleDegreeChange("bachelors")}
                />
                <label htmlFor="bachelors">Bachelor's Degree</label>
              </div>
              <div className="my-2">
                <input
                  type="checkbox"
                  id="masters"
                  className="mr-2"
                  checked={selectedDegrees.masters}
                  onChange={() => handleDegreeChange("masters")}
                />
                <label htmlFor="masters">Master's Degree</label>
              </div>
              <div className="my-2">
                <input
                  type="checkbox"
                  id="phd"
                  className="mr-2"
                  checked={selectedDegrees.phd}
                  onChange={() => handleDegreeChange("phd")}
                />
                <label htmlFor="phd">PHD</label>
              </div>
            </div>
          </div>
        </div>
        <div className="w-[85%]">
          <div className="ui raised segment">
            {projectLoading ? (
              <div className="flex justify-center w-[100%]">
                <span className="loading loading-bars loading-lg"></span>
              </div>
            ) : (
              <>
                {filteredProjects.length ? (
                  <div>
                    <ProjectTable projects={filteredProjects} />
                  </div>
                ) : (
                  <div>No projects found</div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
