import moment from "moment";

export const formatDate = (date) => {
  const d = new Date(date);
  const month = "" + (d.getMonth() + 1);
  const day = "" + d.getDate();
  const year = d.getFullYear();

  return [year, month.padStart(2, "0"), day.padStart(2, "0")].join("-");
};

export const getFileNameFromUrl = (url) => {
  return url.split("/").pop();
};

export const truncateText = (text) => {
  if (text.length > 60) {
    return text.substring(0, 60) + "...";
  }
  return text;
};

export const formatTimeLeft = (eventTime) => {
  const now = moment();
  const eventMoment = moment(eventTime);
  const duration = moment.duration(eventMoment.diff(now));

  if (duration.asMinutes() < 0) {
    return "Event has passed";
  } else if (duration.asDays() >= 1) {
    return `${Math.floor(duration.asDays())} day${
      Math.floor(duration.asDays()) > 1 ? "s" : ""
    } remain`;
  } else if (duration.asHours() >= 1) {
    return `${Math.floor(duration.asHours())} hour${
      Math.floor(duration.asHours()) > 1 ? "s" : ""
    } remain`;
  } else {
    return `${Math.floor(duration.asMinutes())} min remain`;
  }
};


export const formatViews = (num) => {
  if (num >= 1_000_000_000) {
    return (num / 1_000_000_000).toFixed(1).replace(/\.0$/, '') + 'B';
  }
  if (num >= 1_000_000) {
    return (num / 1_000_000).toFixed(1).replace(/\.0$/, '') + 'M';
  }
  if (num >= 1_000) {
    return (num / 1_000).toFixed(1).replace(/\.0$/, '') + 'K';
  }
  return num;
};


export const cutString = (string, num) => {
  if (string.length <= num) {
    return string;
  }
  return string.slice(0, num);
};


export const calculateTimeRemaining = (eventTime) => {
  const now = moment();
  const eventMoment = moment(eventTime);
  const duration = moment.duration(eventMoment.diff(now));

  if (duration.asSeconds() <= 0) {
    return { ended: true };
  }

  return {
    days: Math.floor(duration.asDays()),
    hours: duration.hours(),
    minutes: duration.minutes(),
    seconds: duration.seconds(),
    ended: false
  };
};
