import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "../../../helpers/axios";
import { toast, ToastContainer } from "react-toastify";
import QuillEditor from "../../QuillEditor";
import { GoBack } from "../../GoBack";

export const EditVideo = () => {
  const { id } = useParams();
  const [video, setVideo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [updating, setUpdating] = useState(false);
  const [posterImagePreview, setPosterImagePreview] = useState(
    video?.poster_image || ""
  );
  const [categories, setCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);

  const navigate = useNavigate();
  // redirect back to login
  useEffect(() => {
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    if (!userInfo?.userEmail) {
      navigate("/login");
      return;
    }
  }, []);

  useEffect(() => {
    const fetchVideo = async () => {
      try {
        const response = await axios.get(`/nsangiza/video/${id}/`);
        setVideo(response.data);
        setPosterImagePreview(response.data.poster_image);
        console.log("response", response);
      } catch (error) {
        console.error("Error fetching video:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchVideo();
  }, [id]);

  useEffect(() => {
    axios
      .get("/add-categories/")
      .then((res) => {
        setCategories(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setUpdating(true);
    try {
      const formData = new FormData();
      for (const key in video) {
        formData.append(key, video[key]);
      }
      const response = await axios.patch(`/nsangiza/video/${id}/`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (response.data) {
        toast.success("Video updated successfully");
      } else {
        toast.error("Failed to update video");
      }
    } catch (error) {
      console.error("Error updating video:", error);
      toast.error("Failed to update video");
    } finally {
      setUpdating(false);
    }
  };

  //   const handleChange = (e) => {
  //     const { name, value } = e.target;
  //     setVideo((prevVideo) => ({
  //       ...prevVideo,
  //       [name]: value,
  //     }));
  //   };

  const handleQuillChange = (value) => {
    setVideo((prevVideo) => ({
      ...prevVideo,
      description: value,
    }));
  };

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (name === "poster_image" || name === "video_file") {
      if (files && files.length > 0) {
        const reader = new FileReader();
        reader.onload = (e) => setPosterImagePreview(e.target.result);
        reader.readAsDataURL(files[0]);
        setVideo((prevVideo) => ({
          ...prevVideo,
          [name]: files[0],
        }));
      } else {
        if (name === "poster_image") {
          setPosterImagePreview(value);
        }
        setVideo((prevVideo) => ({
          ...prevVideo,
          [name]: value,
        }));
      }
    } else {
      setVideo((prevVideo) => ({
        ...prevVideo,
        [name]: value,
      }));
    }
  };

  if (loading) {
    return (
      <div class="flex justify-center w-[100%]">
        <span class="loading loading-bars loading-lg"></span>
      </div>
    );
  }

  return (
    <div className="container mx-auto my-5">
      <GoBack />
      <ToastContainer />
      <h1 className="text-center text-3xl font-bold">Edit Video</h1>
      <form
        onSubmit={handleSubmit}
        className="mt-5 p-5 border rounded shadow w-full sm:w-[90%] md:w-[80%] lg:w-[70%] xl:w-[60%] mx-auto"
      >
        <div className="mb-4">
          <label className="block mb-2">Title:</label>
          <input
            type="text"
            name="title"
            value={video.title}
            onChange={handleChange}
            className="w-full p-2 border rounded"
          />
        </div>
        <div className="mb-4">
          <label className="block mb-2">Description:</label>
          <QuillEditor value={video.description} onChange={handleQuillChange} />
        </div>
        <div className="mb-4">
          <label className="block mb-2">Date:</label>
          <input
            type="datetime-local"
            name="date"
            value={video.date.slice(0, 16)}
            onChange={handleChange}
            className="w-full p-2 border rounded"
          />
        </div>
        <div className="mb-4">
          <label className="block mb-2">Category:</label>
          <select
            name="categories"
            value={video.categories[0] || ""}
            onChange={(e) =>
              setVideo((prevVideo) => ({
                ...prevVideo,
                categories: [parseInt(e.target.value)],
              }))
            }
            className="select select-bordered w-full"
          >
            <option value="">Select a category</option>
            {categories.map((category) => (
              <option key={category.id} value={category.id}>
                {category.name}
              </option>
            ))}
          </select>
          {video.categories[0] && (
            <p className="mt-2 text-sm text-gray-600 ">
              Selected Category:{" "}
              <span className="font-medium">
                {categories.find((cat) => cat.id === video.categories[0])?.name}
              </span>
            </p>
          )}
        </div>
        <div className="mb-4">
          <label className="block mb-2">Poster Image URL:</label>

          <input
            type="file"
            name="poster_image"
            className="w-full p-2 border rounded"
            onChange={handleChange}
            accept="image/*"
          />
          {posterImagePreview && (
            <div className="mt-2">
              <img
                src={posterImagePreview}
                alt="Poster Preview"
                className="w-[100px] h-[100px] object-cover my-2"
              />
            </div>
          )}
        </div>
        {/* <div className="mb-4">
          <label className="block mb-2">Video File URL:</label>
          
          <input
            type="file"
            name="video_file"
            onChange={handleChange}
            className="w-full p-2 border rounded"
          />
          <p>
            <Link to={`/nsangiza/video/${video.id}`}>
              Click Here to view video
            </Link>
          </p>
        </div> */}
        <div className="mb-4">
          <label className="block mb-2">Youtube Link:</label>
          <input
            type="url"
            name="youtube_link"
            value={video.youtube_link}
            onChange={handleChange}
            className="w-full p-2 border rounded"
          />
        </div>

        <button
          type="submit"
          className="bg-blue-500 text-white px-4 py-2 rounded"
          disabled={updating}
        >
          {updating ? "Updating..." : "Update Video"}
        </button>
      </form>
    </div>
  );
};
